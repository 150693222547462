import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemButton,
  Menu,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentSubsState,
  userSubsListState,
} from "../../../../store/atoms/AppState";
import { getSubsCloudTypeIcon } from "../../../../util/app.util";
import { forwardRef, useState } from "react";
import Auth from "../../../../services/auth.service";
import Session from "../../../../services/session.service";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastNotify from "../../../../components/Common/ToastNotify";
import { XGDCancel } from "../../../../components/XGENUI/Buttons/Dialogs/XGDCancel";
import { XGDSwitch } from "../../../../components/XGENUI/Buttons/Dialogs/XGDSwitch";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubsList({ anchorSubsEl, onClose }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const openSubsList = Boolean(anchorSubsEl);
  const userSubsList = useRecoilValue(userSubsListState);
  const [currentSubs, setCurrentSubs] = useRecoilState(currentSubsState);

  const [showSwitchSubs, setShowSwitchSubs] = useState(false);
  const [switchToSubs, setSwitchToSubs] = useState({});

  const handleSwitchSubs = (subs) => {
    setSwitchToSubs(subs);
    setShowSwitchSubs(true);
  };

  const handleCloseSwitchSubs = (e, reason) => {
    if (reason !== "backdropClick") {
      setShowSwitchSubs(false);
    }
  };

  const handleSwitchSubsSession = async () => {
    setShowSwitchSubs(false);
    try {
      const resp = await Auth.usersubslogin(switchToSubs.subscription_id);
      let access = {};

      //const cuser = Session.getUser();
      access = resp.data.useraccess;

      const subsdetails = {
        subsid: switchToSubs.subscription_id,
        subsname: switchToSubs.subscription_name,
        access: access,
      };

      Session.setCurrentSubs(subsdetails);
      setCurrentSubs(Session.getCurrentSubs());

      if (state) {
        navigate(state.path);
      } else {
        navigate("/");
      }

      onClose();
    } catch (err) {
      toast.error(
        <ToastNotify
          title="Subscription Login Error"
          detail={err.response.data.message}
        />
      );
    }
  };

  return (
    <>
      <Menu
        id="subs-list"
        anchorEl={anchorSubsEl}
        open={openSubsList}
        onClose={onClose}
        MenuListProps={{
          dense: true,
        }}
      >
        {userSubsList?.map((subs) => (
          <ListItemButton
            dense={true}
            disabled={currentSubs.subsid === subs.subscription_id}
            key={subs.subscription_id}
            selected={currentSubs.subsid === subs.subscription_id}
            onClick={(e) => handleSwitchSubs(subs)}
          >
            <ListItem key={subs.id}>
              <Box
                display="inline-flex"
                sx={{
                  width: "100%",
                  //alignItems: "flex-start",
                  //textAlign: "flex-start",
                  justifyContent: "space-around",
                  columnGap: 3,
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    {getSubsCloudTypeIcon(subs.cloud_type, theme)}
                  </Typography>
                </Box>

                <Box sx={{ width: "100%", textAlign: "right" }}>
                  <Typography variant="caption">{`${subs.subscription_name} (${subs.subscription_id})`}</Typography>
                </Box>
              </Box>
            </ListItem>
          </ListItemButton>
        ))}
      </Menu>

      {/* Dialog to Confirm Subscription Switch */}
      <Dialog
        open={showSwitchSubs}
        onClose={handleCloseSwitchSubs}
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
          },
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Typography>Switch Subscription</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ justifyContent: "center" }}>
          <DialogContentText>
            Are your sure you want to switch to a different subscription?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <XGDSwitch onClick={handleSwitchSubsSession} />
          <XGDCancel onClick={handleCloseSwitchSubs} />
        </DialogActions>
      </Dialog>
    </>
  );
}
