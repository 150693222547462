//React

//Mui
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

//Icons

//3rd Party
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

//Css

//Local
import { sideF1Menu, sideF2Menu } from "../../../../routes/appmenu";

//Components

//Recoil State
import {
  currentModuleState,
  menuDraweropenState,
} from "../../../../store/atoms/AppState";

//API Service
import Auth from "../../../../services/auth.service";

//Const

export default function NavItem() {
  const theme = useTheme();
  const currentModule = useRecoilValue(currentModuleState);
  const menuDraweropen = useRecoilValue(menuDraweropenState);
  return (
    <>
      <List>
        {sideF1Menu.map((mlist) => (
          <ListItem
            key={mlist.id}
            disablePadding
            sx={{
              display: "block",
              mb: 1,
              mt: 1,
            }}
          >
            <ListItemButton
              key={mlist.id}
              disabled={
                mlist.module !== undefined &&
                !Auth.hasModuleAccess(mlist.module)
              }
              sx={{
                px: 1,
                py: 0.5,
              }}
              component={Link}
              to={mlist.navto}
            >
              <Box
                display="inline-flex"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 45,
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Tooltip title={mlist.desc}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        //mr: menuDraweropen ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.grey[600]
                            : theme.palette.grey[200],
                        ...(mlist.module.includes(currentModule) && {
                          color: theme.palette.primary.main,
                        }),
                      }}
                    >
                      {mlist.module.includes(currentModule)
                        ? mlist.sel_icon
                        : mlist.unsel_icon}
                    </ListItemIcon>
                  </Tooltip>
                  {!menuDraweropen && (
                    <Typography
                      variant="caption"
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.grey[600]
                            : theme.palette.grey[200],
                        ...(mlist.module.includes(currentModule) && {
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }),
                      }}
                    >
                      {mlist.stext}
                    </Typography>
                  )}
                </Box>
                {menuDraweropen && (
                  <ListItemText
                    primary={
                      <Typography variant="body1">{mlist.desc}</Typography>
                    }
                    sx={{
                      opacity: menuDraweropen ? 1 : 0,
                      ...(mlist.module.includes(currentModule) && {
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }),
                    }}
                  />
                )}
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider />
      <List>
        {sideF2Menu.map((mlist) => (
          <ListItem
            key={mlist.id}
            disablePadding
            sx={{
              display: "block",
              mb: 1,
              mt: 1,
            }}
          >
            <ListItemButton
              key={mlist.id}
              disabled={
                mlist.module !== undefined &&
                !Auth.hasModuleAccess(mlist.module)
              }
              sx={{
                px: 1,
                py: 0.5,
              }}
              component={Link}
              to={mlist.navto}
              state={mlist.state}
            >
              <Box
                display="inline-flex"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 45,
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Tooltip title={mlist.desc}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        //mr: menuDraweropen ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.grey[600]
                            : theme.palette.grey[200],
                        ...(mlist.module.includes(currentModule) && {
                          color: theme.palette.primary.main,
                        }),
                      }}
                    >
                      {mlist.module.includes(currentModule)
                        ? mlist.sel_icon
                        : mlist.unsel_icon}
                    </ListItemIcon>
                  </Tooltip>
                  {!menuDraweropen && (
                    <Typography
                      variant="caption"
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? theme.palette.grey[600]
                            : theme.palette.grey[200],
                        ...(mlist.module.includes(currentModule) && {
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }),
                      }}
                    >
                      {mlist.stext}
                    </Typography>
                  )}
                </Box>
                {menuDraweropen && (
                  <ListItemText
                    primary={
                      <Typography variant="body1">{mlist.desc}</Typography>
                    }
                    sx={{
                      opacity: menuDraweropen ? 1 : 0,
                      ...(mlist.module.includes(currentModule) && {
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }),
                    }}
                  />
                )}
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
