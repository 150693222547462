//React
import { useMemo } from "react";
import PropTypes from "prop-types";

//Mui
import { Box } from "@mui/material";

//Icons

//3rd Party

//Css

//Local

//Components
import DrawerHeader from "./DrawerHeader";
import MiniDrawerStyled from "./MiniDrawerStyled";
import DrawerContent from "./DrawerContent";

//Recoil State

//API Service

//Const

const AppDrawer = ({ open }) => {
  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
      aria-label="Menu"
    >
      <MiniDrawerStyled variant="permanent" open={open}>
        {drawerHeader}
        {drawerContent}
      </MiniDrawerStyled>
    </Box>
  );
};

AppDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default AppDrawer;
