//React
import { useState } from "react";

//Mui
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

//Icons
import HelpIcon from "@mui/icons-material/HelpOutlineOutlined";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import SystemHealthIcon from "@mui/icons-material/MedicalInformationOutlined";
import SubsIcon from "@mui/icons-material/ExpandMoreOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import GoBackIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

//3rd Party
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Css
import xGenLogo from "../../../../assets/logo/xgenlogo.png";

//Local
import { stringAvatar } from "../../../../util/app.util";
import ThemeToggler from "../../../../themes/ThemeToggler";
import ToastNotify from "../../../../components/Common/ToastNotify";

//Components
import SubsList from "./SubsList";
import HelpSupport from "./HelpSupport";
import HealthCheck from "./HealthCheck";

//Recoil State
import {
  currentSubsState,
  currentUserState,
  menuDraweropenState,
  userSubsListState,
} from "../../../../store/atoms/AppState";

//API Service
import Auth from "../../../../services/auth.service";

//Const

const HeaderContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const currentSubs = useRecoilValue(currentSubsState);
  const userSubsList = useRecoilValue(userSubsListState);
  const currentUser = useRecoilValue(currentUserState);
  const [menuDraweropen, setMenuDrawerOpen] =
    useRecoilState(menuDraweropenState);

  const [anchorSubsEl, setAnchorSubsEl] = useState(null);
  const [anchorHelpEl, setAnchorHelpEl] = useState(null);
  const [anchorHealthEl, setAnchorHealthEl] = useState(null);

  //////////////////UI Functions///////////////////////

  const handleGoBackNav = () => {
    if (location.pathname !== "/") {
      navigate(-1);
    }
  };

  const handleSubsListClose = () => {
    setAnchorSubsEl(null);
  };

  const handleSubsListOpen = (event) => {
    setAnchorSubsEl(event.currentTarget);
  };

  const handleHelpListClose = () => {
    setAnchorHelpEl(null);
  };

  const handleHelpListOpen = (event) => {
    setAnchorHelpEl(event.currentTarget);
  };

  const handleHealthListClose = () => {
    setAnchorHealthEl(null);
  };

  const handleHealthListOpen = (event) => {
    setAnchorHealthEl(event.currentTarget);
  };

  const handleMenuDrawerOpen = () => {
    setMenuDrawerOpen(true);
  };

  //hanlde application logout
  const handleLogout = async () => {
    try {
      await Auth.logout();
      navigate("/login");
    } catch (err) {
      toast.error(
        <ToastNotify
          title={err.response?.data.message || err.message}
          detail={err.response?.data.details || ""}
        />
      );
    }
  };

  return (
    <>
      {/**XGEN Icon */}
      <IconButton
        aria-label="open drawer"
        onClick={handleMenuDrawerOpen}
        edge="start"
        sx={{
          marginRight: 5,
          ...(menuDraweropen && { display: "none" }),
        }}
      >
        {/* <MenuIcon /> */}
      </IconButton>

      {/**GO Back Button */}
      <Box sx={{ mr: 1 }}>
        {location.pathname !== "/" && (
          <IconButton size="medium" onClick={handleGoBackNav}>
            <GoBackIcon
              sx={{ fontSize: "medium", color: theme.palette.common.white }}
            />
          </IconButton>
        )}
      </Box>

      {/**Account Subscription Details */}
      <Box
        display="inline-flex"
        sx={{ flexGrow: 1, alignItems: "center", columnGap: 0.5 }}
      >
        <Typography variant="h6" component="div">
          {`${
            userSubsList.find((s) => s.subscription_id === currentSubs.subsid)
              ?.account_name
          } / `}
        </Typography>
        <Typography variant="subtitle1" component="div">
          {currentSubs.subsname}
        </Typography>
        <Tooltip title="Switch Subscription">
          <IconButton onClick={handleSubsListOpen}>
            <SubsIcon sx={{ color: theme.palette.common.white }} />
          </IconButton>
        </Tooltip>
        <SubsList anchorSubsEl={anchorSubsEl} onClose={handleSubsListClose} />
      </Box>

      {/**Global Search */}

      {/**Action Items */}
      <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
        <Box
          display="inline-flex"
          sx={{
            width: "100%",
            alignItems: "center",
            columnGap: "2",
          }}
        >
          {/** Help & Support*/}
          <Tooltip title="Help & Support">
            <span>
              <IconButton size="medium" onClick={handleHelpListOpen}>
                <HelpIcon
                  fontSize="medium"
                  sx={{ color: theme.palette.common.white }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <HelpSupport
            anchorHelpEl={anchorHelpEl}
            onClose={handleHelpListClose}
          />

          {/** User Notifications*/}
          <Tooltip title="Notifications">
            <span>
              <IconButton size="medium">
                <NotificationsIcon
                  fontSize="medium"
                  sx={{ color: theme.palette.common.white }}
                />
              </IconButton>
            </span>
          </Tooltip>

          {/** System Health Check*/}
          <Tooltip title="System Health">
            <span>
              <IconButton size="medium" onClick={handleHealthListOpen}>
                <SystemHealthIcon
                  fontSize="medium"
                  sx={{ color: theme.palette.common.white }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <HealthCheck
            anchorHealthEl={anchorHealthEl}
            onClose={handleHealthListClose}
          />

          {/** Theme Mode Changer */}
          <ThemeToggler />

          {/** User Logout */}
          <Tooltip title="Logout">
            <span>
              <IconButton size="medium" onClick={handleLogout}>
                <LogoutIcon
                  fontSize="medium"
                  sx={{ color: theme.palette.common.white }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </Box>

        {/** User Avatar & Name */}
        <Box
          display="inline-flex"
          sx={{ width: "100%", alignItems: "center", columnGap: 2 }}
          //onClick={handleUserMenuDrawerOpen}
        >
          <Avatar
            sx={{
              backgroundColor: theme.palette.background.appbar,
              width: 30,
              height: 30,
            }}
            {...stringAvatar(
              currentUser.first_name + " " + currentUser.last_name,
              theme
            )}
          />

          <Tooltip
            title={
              <Box>
                <Typography variant="body2">{`${currentUser.user_id}`}</Typography>
                <Typography variant="body2">
                  {currentUser.is_super && "Super User"}
                </Typography>
              </Box>
            }
          >
            <Typography>
              {`${currentUser.first_name} ${currentUser.last_name}`}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
    </>
  );
};

export default HeaderContent;
