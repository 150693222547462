export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          fontSize: 12,
          borderWidth: 1,
          //minWidth: 50,
          "&:hover": {
            borderWidth: 1,
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
        },
        {
          props: { variant: "outlined" },
        },
        {
          props: { variant: "dashed" },
          style: {
            border: "1px dashed",
            borderColor: theme.palette.secondary.main,
          },
        },
      ],
    },
  };
}
