import { Button, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Clear";

export const XGDCancel = (props) => {
  return (
    <Button
      size="small"
      startIcon={<CancelIcon />}
      color="secondary"
      variant="contained"
      {...props}
    >
      <Typography variant="body2">Cancel</Typography>
    </Button>
  );
};
