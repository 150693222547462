import { Button, Typography } from "@mui/material";
import SwitchIcon from "@mui/icons-material/TuneOutlined";

export const XGDSwitch = (props) => {
  return (
    <Button
      size="small"
      startIcon={<SwitchIcon />}
      color="success"
      variant="contained"
      {...props}
    >
      <Typography variant="body2">Switch</Typography>
    </Button>
  );
};
