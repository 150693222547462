//React

//MUI
import { Typography } from "@mui/material";

//ICONS

//3rd-Party

//Local

//Components

//Recoil State

//API Service

//Const

export default function ToastNotify({ title, detail }) {
  return (
    <>
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
      <Typography variant="caption">{detail}</Typography>
    </>
  );
}
