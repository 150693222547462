import { Box, styled } from "@mui/material";

const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-start" : "center",
  paddingLeft: theme.spacing(open ? 3 : 0),
  backgroundColor: theme.palette.background.sidenav,
  //color: theme.palette.common.white,
}));

export default DrawerHeaderStyled;
