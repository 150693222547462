//React
import { forwardRef, useEffect, useState } from "react";

//Mui
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";

//Icons
import LogoutIcon from "@mui/icons-material/Logout";

//3rd Party
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Css
import "reactflow/dist/style.css";

//Local
import ToastNotify from "../../components/Common/ToastNotify";
import useIdle from "../../hooks/useIdleTimer";
//import { useViewport } from "../../context/viewport-context";

//Components
import Drawer from "./Drawer";
import Header from "./Header";
import DrawerHeaderStyled from "./Drawer/DrawerHeader/DrawerHeaderStyled";

//Recoil State
import {
  currentSubsState,
  currentUserState,
  menuDraweropenState,
  sysHealthState,
  userSubsListState,
} from "../../store/atoms/AppState";

//API Service
import Session from "../../services/session.service";
import OverviewApi from "../../services/overview.service";
import Auth from "../../services/auth.service";
import { useViewport } from "../../context/viewport-context";

//Const
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppLayout = () => {
  //const theme = useTheme();
  const { width, height } = useViewport();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const setCurrentSubs = useSetRecoilState(currentSubsState);
  const setUserSubsList = useSetRecoilState(userSubsListState);

  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const navigate = useNavigate();

  const menuDraweropen = useRecoilValue(menuDraweropenState);

  const setSysHealth = useSetRecoilState(sysHealthState);

  // drawer toggler
  const [open, setOpen] = useState(menuDraweropen);

  ////////////////////////////
  //this function takes milliseconds and converts them to min and seconds.
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  const handleIdle = () => {
    setShowModal(true); //show modal
    setRemainingTime(30); //set 30 seconds as time remaining
  };

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 30 });

  useEffect(() => {
    if (open !== menuDraweropen) setOpen(menuDraweropen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuDraweropen]);

  useEffect(() => {
    setCurrentUser(Session.getUser());
    setCurrentSubs(Session.getCurrentSubs());
    setUserSubsList(Session.getUserSubsList());
    console.log("Screen Height: " + height);
    console.log("Screen Width: " + width);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let interval;

    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
        );
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      // alert("Time out!");
      handleLogOut();
    }
    // eslint-disable-next-line
  }, [remainingTime, showModal, navigate]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything

  const handleLogOut = async () => {
    setShowModal(false);

    try {
      await Auth.logout();
      navigate("/login");
    } catch (err) {
      toast.error(
        <ToastNotify
          title={err.response?.data.message || err.message}
          detail={err.response?.data.details || ""}
        />
      );
    }
  };

  const handleStayLoggedIn = () => {
    setShowModal(false);
  };

  ///////////////////////////

  ///////////////GET System Health///////////////
  const fetchSystemHealthDetails = async () => {
    try {
      const resp = await OverviewApi.getSystemHealthCheck();
      setSysHealth(resp.data);
    } catch (err) {
      toast.error(
        <ToastNotify
          title={err.response?.data.message || err.message}
          detail={err.response?.data.details || ""}
        />
      );
    }
  };

  useEffect(() => {
    fetchSystemHealthDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchSystemHealthDetails();
    }, 300000); // Fetch data every 5 mins
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Header open={open} />
        <Drawer open={open} />

        <Box component="main" sx={{ flexGrow: 1 }}>
          <DrawerHeaderStyled />
          <Container
            className="container"
            disableGutters={true}
            maxWidth={false}
            sx={{
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Outlet />
          </Container>
        </Box>
      </Box>

      {/** Dialog for Logout Confirmation after a level of inactivity */}
      {isIdle !== null && (
        <Dialog
          open={isIdle && showModal}
          //onClose={handleCloseModelTypeChgDia}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            <Typography>Idle Timeout Warning</Typography>
          </DialogTitle>

          <DialogContent dividers sx={{ justifyContent: "center" }}>
            <DialogContentText>
              <p>You are about to be logged out due to inactivity.</p>
              <br />
              Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}
              <br />
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              size="small"
              startIcon={<LogoutIcon sx={{ fontSize: "medium" }} />}
              color="warning"
              variant="contained"
              onClick={handleLogOut}
            >
              <Typography variant="body2">Logout</Typography>
            </Button>

            <Button
              size="small"
              startIcon={<LogoutIcon sx={{ fontSize: "medium" }} />}
              color="success"
              variant="contained"
              onClick={handleStayLoggedIn}
            >
              <Typography variant="body2">Stay Logged In</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AppLayout;
