import { AppBar, styled } from "@mui/material";
import { APPBAR_HEIGHT, DRAWER_WIDTH } from "../../../constants/appconstant";

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  height: APPBAR_HEIGHT,
  justifyContent: "center",
  //borderBottom: "1px solid",
  //borderColor: alpha(theme.palette.primary.main, 0.2),

  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default AppBarStyled;
