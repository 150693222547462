export default function Avatar(theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          transition: "all 200ms ease-in-out",
          border: "1px solid white",
        },

        rounded: {
          borderRadius: theme.borders.borderRadius.lg,
        },

        img: {
          height: "auto",
        },
      },
    },
  };
}
