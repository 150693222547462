import { APPBAR_HEIGHT } from "../constants/appconstant";
import { createTheme, responsiveFontSizes } from "@mui/material";

import colorsLight from "./theme-light/colors";
import colorsDark from "./theme-dark/colors";
import typography from "./typography";
import typographyLight from "./theme-light/typography.js";
import typographyDark from "./theme-dark/typography.js";
import boxShadowsLight from "./theme-light/boxShadows";
import boxShadowsDark from "./theme-dark/boxShadows";
import bordersLight from "./theme-light/borders";
import bordersDark from "./theme-dark/borders";

import boxShadow from "./functions/boxShadow";
import hexToRgb from "./functions/hexToRgb";
import linearGradient from "./functions/linearGradient";
import pxToRem from "./functions/pxToRem";
import rgba from "./functions/rgba";
import ComponentsOverrides from "./overrides";
import CustomShadows from "./shadows";

const palette = {
  light: {
    primary: {
      main: "#0d72b9",
      light: "#3D8EC7",
      dark: "#094F81",
    },
    secondary: {
      main: "#c11750",
      light: "#00D7CF",
    },
    error: {
      main: "#cd372c",
    },
    warning: {
      main: "#a36200",
    },
    info: {
      main: "#4c71a1",
    },
    success: {
      main: "#357e38",
    },
  },
  dark: {
    primary: {
      main: "#138ee4",
      light: "#42A4E9",
      dark: "#0D639F",
    },
    secondary: {
      main: "#ee1f64",
      light: "#00D7CF",
    },
    error: {
      main: "#fb4538",
    },
    warning: {
      main: "#fcab65",
    },
    info: {
      main: "#7ab2fa",
    },
    success: {
      main: "#57c55c",
    },
  },
};

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light" ? colorsLight : colorsDark),
  },

  //typography: mode === "light" ? typographyLight : typographyDark,
  typography: typography,

  boxShadows: mode === "light" ? boxShadowsLight : boxShadowsDark,
  borders: mode === "light" ? bordersLight : bordersDark,
  customShadows: CustomShadows(mode),

  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  mixins: {
    toolbar: {
      minHeight: APPBAR_HEIGHT,
    },
  },
});

/* const getThemedComponents = (theme) => ({
  components: ComponentsOverrides(theme),
});
 */
export const getThemeOptions = (mode) => {
  let theme = createTheme(getDesignTokens(mode));
  theme.components = ComponentsOverrides(theme);
  theme = responsiveFontSizes(theme);
  return theme;
};
