//React
import PropTypes from "prop-types";

//Mui
import { Toolbar, useTheme } from "@mui/material";

//Icons

//3rd Party

//Css

//Local

//Components
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

//Recoil State

//API Service

//Const

const Header = ({ open }) => {
  const theme = useTheme();

  // common header
  const AppHeader = (
    <Toolbar>
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.customShadows.z1,
    },
  };

  return (
    <>
      <AppBarStyled open={open} {...appBar}>
        {AppHeader}
      </AppBarStyled>
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default Header;
