import api from "./api.service";

const getSystemHealthCheck = async () => {
  return api.get("/overview/health");
};

const getListofValues = async (module) => {
  return api.get("/overview/list_of_values?module=" + module);
};

const updateListofValues = async (data) => {
  return api.patch("/overview/list_of_values", data);
};

const updateObjectHeaderInfo = async (obj_id, data) => {
  return api.patch("overview/" + obj_id + "/update-header", data);
};

const getObjectXID = async (obj_type, obj_name) => {
  return api.get("/overview/xid/" + obj_type + "/" + obj_name);
};

const getObjectsOverview = async () => {
  return api.get("/overview/objects-overview");
};

const OverviewApi = {
  getSystemHealthCheck,
  getListofValues,
  updateListofValues,
  updateObjectHeaderInfo,
  getObjectXID,
  getObjectsOverview,
};

export default OverviewApi;
