import pxToRem from "../functions/pxToRem";

export default function Menu(theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: pxToRem(160),
          boxShadow: theme.boxShadows.lg,
          padding: `${pxToRem(16)} ${pxToRem(8)}`,
          //fontSize: themesize.sm,
          color: theme.palette.text.main,
          textAlign: "left",
          backgroundColor: `${theme.palette.background.card} !important`,
          borderRadius: theme.borders.borderRadius.md,
        },
      },
    },
  };
}
