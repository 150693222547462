import { merge } from "lodash";
import AppBar from "./AppBar";
import Badge from "./Badge";
import Button from "./Button";
import Card from "./Card";
import CardContent from "./CardContent";
import CheckBox from "./CheckBox";
import Chip from "./Chip";
import IconButton from "./IconButton";
import LinearProgress from "./LinearProgress";
import Link from "./Link";
import ListItemIcon from "./ListItemIcon";
import OutlinedInput from "./OutlinedInput";
import Popover from "./Popover";
import Tab from "./Tab";
import TableCell from "./TableCell";
import Tabs from "./Tabs";

import ToolBar from "./Toolbar";

import Typography from "./Typography";
import Avatar from "./Avatar";
import InputLabel from "./InputLabel";
import Tooltip from "./Tooltip";
import CardHeader from "./CardHeader";
import Drawer from "./Drawer";
import DialogTitle from "./DialogTitle";
import Dialog from "./Dialog";
import DialogContent from "./DialogContent";
import DialogContentText from "./DialogContentText";
import DialogActions from "./DialogActions";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import TableContainer from "./TableContainer";
import TableHead from "./TableHead";
import Paper from "./Paper";

export default function ComponentsOverrides(theme) {
  return merge(
    AppBar(theme),
    Drawer(theme),
    Avatar(theme),
    Badge(theme),
    Button(theme),
    Card(theme),
    CardHeader(theme),
    CardContent(theme),
    //CheckBox(theme),
    //Chip(theme),
    //IconButton(theme),
    //InputLabel(theme),
    //LinearProgress(theme),
    Link(theme),
    //ListItemIcon(theme),
    OutlinedInput(theme),
    Popover(theme),
    //Tab(theme),
    TableContainer(theme),
    TableHead(theme),
    TableCell(theme),

    //Tabs(theme),
    //ToolBar(theme),
    Menu(theme),
    MenuItem(theme),
    Tooltip(theme),
    Typography(theme),
    Dialog(theme),
    DialogTitle(theme),
    DialogContent(theme),
    DialogContentText(theme),
    DialogActions(theme),
    Paper(theme)
  );
}
