import rgba from "../functions/rgba";

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          position: "relative",
          minWidth: 0,
          wordWrap: "break-word",
          backgroundColor: theme.palette.background.card,
          backgroundClip: "border-box",
          border: `${theme.borders.borderWidth[0]} solid ${rgba(
            theme.palette.black.main,
            0.125
          )}`,
          borderRadius: theme.borders.borderRadius.xl,
          boxShadow: theme.boxShadows.md,
          overflow: "visible",
        },
      },
    },
  };
}
