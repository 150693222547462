//React

//Mui

//Icons

//3rd Party

//Css

//Local

//Components
import SimpleBar from "./SimpleBar";
import NavItem from "./NavItem";
import NavFooter from "./NavFooter";

//Recoil State

//API Service

//Const

const DrawerContent = () => (
  <SimpleBar
    sx={{
      "& .simplebar-content": {
        display: "flex",
        flexDirection: "column",
      },
    }}
  >
    <NavItem />
    <NavFooter />
  </SimpleBar>
);

export default DrawerContent;
