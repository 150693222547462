export default function Link() {
  return {
    MuiLink: {
      variant: "h3",
      defaultProps: {
        underline: "hover",
      },
    },
  };
}
