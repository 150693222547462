import { Navigate, useLocation } from "react-router-dom";
import Auth from "../services/auth.service";

function AuthorizedModule({ module, children }) {
  const location = useLocation();

  if (Auth.hasModuleAccess(module)) {
    return children;
  } else {
    return (
      <Navigate
        //to={`${signInURL}?from=${location.pathname}`}
        to={{
          pathname: "/",
          search: `from=${location.pathname}`,
          state: { from: location },
        }}
        replace
      />
    );
  }
}

export default AuthorizedModule;
