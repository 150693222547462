import pxToRem from "../functions/pxToRem";

export default function DialogActions(theme) {
  return {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: pxToRem(16),
        },
      },
    },
  };
}
