//React

//Mui
import { Box, Tooltip, useTheme } from "@mui/material";

//Icons

//3rd Party
import { useRecoilValue } from "recoil";

//Css

//Local
import { getSubsCloudTypeIcon } from "../../../../util/app.util";

//Components

//Recoil State
import {
  currentSubsState,
  userSubsListState,
} from "../../../../store/atoms/AppState";

//API Service

//Const

export default function NavFooter() {
  const theme = useTheme();
  const currentSubs = useRecoilValue(currentSubsState);
  const userSubsList = useRecoilValue(userSubsListState);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        textAlign: "center",
        alignItems: "center",
        paddingBottom: 2,
        ml: 2,
      }}
    >
      <Tooltip
        title={
          userSubsList.find((s) => s.subscription_id === currentSubs.subsid) !==
          undefined
            ? userSubsList.find((s) => s.subscription_id === currentSubs.subsid)
                ?.cloud_type || ""
            : ""
        }
      >
        <>
          {getSubsCloudTypeIcon(
            userSubsList.find((s) => s.subscription_id === currentSubs.subsid)
              ?.cloud_type,
            theme
          )}
        </>
      </Tooltip>
    </Box>
  );
}
