import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

//containers
import AuthorizedRoute from "../containers/AuthorizedRoutes";
import AuthorizedModule from "../containers/AuthorizedModules";
import AppLayout from "../layout/AppLayout";
import ErrorPage from "../pages/Common/ErrorPage";
import PageLayout from "../layout/PageLayout";

//Login
import LoginLayout from "../layout/LoginLayout";
import Login from "../pages/Login/Login";

//Home
const Home = lazy(() => import("../pages/Home"));
const Design = lazy(() => import("../pages/Common/DesignPage"));

//Sync
const ConnectionsSummary = lazy(() =>
  import("../pages/Connections/ConnectionsSummary")
);
const Connection = lazy(() => import("../pages/Connections/Connection"));

//Model
const DataModelsSummary = lazy(() =>
  import("../pages/Modelling/DataModelsSummary")
);
const DataModel = lazy(() => import("../pages/Modelling/DataModel"));

//Analysis
const DataAnalysis = lazy(() => import("../pages/Analyse/DataAnalysis"));

//Govern
const DataGovernanceSummary = lazy(() =>
  import("../pages/Governance/DataGovernanceSummary")
);
const DataGovernanceDetails = lazy(() =>
  import("../pages/Governance/DataGovernanceDetails")
);
const ObjectExplore = lazy(() => import("../pages/Governance/ObjectExplore"));

//Operate
const DataPipelinesSummary = lazy(() =>
  import("../pages/Operations/DataPipelinesSummary")
);
const DataPipeline = lazy(() => import("../pages/Operations/DataPipeline"));
const DataPipelineMonitor = lazy(() =>
  import("../pages/Operations/DataPipelineMonitor")
);

//Connect
const Connect = lazy(() => import("../pages/Connect/Connect"));
const SourceList = lazy(() => import("../pages/Connect/SourceList"));

//UserRoles
const UsersRoles = lazy(() => import("../pages/UserRoles/UsersRoles"));

//Migrate
const Migrate = lazy(() => import("../pages/Migrate/Migrate"));

//BusinessPacks
const BusinessPacks = lazy(() =>
  import("../pages/BusinessPacks/BusinessPacks")
);
const SAPECCPack = lazy(() => import("../pages/BusinessPacks/SAPECCPack"));

//Dashboards
const DataLoadDashboard = lazy(() =>
  import("../pages/Dashboards/DataLoadDashboard")
);
const DataQualityDashboard = lazy(() =>
  import("../pages/Dashboards/DataQualityDashboard")
);
const DataObjectsDashboard = lazy(() =>
  import("../pages/Dashboards/DataObjectsDashboard")
);
const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthorizedRoute>
        <AppLayout />
      </AuthorizedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      //PageLayout
      {
        path: "/",
        element: <PageLayout />,
        children: [
          //HOME
          {
            path: "/",
            element: (
              <AuthorizedModule module={["HOME"]}>
                <Home />
              </AuthorizedModule>
            ),
          },
          {
            path: "/design",
            element: (
              <AuthorizedModule module={["HOME"]}>
                <Design />
              </AuthorizedModule>
            ),
          },

          //Connection Summary
          {
            path: "connection",
            element: (
              <AuthorizedModule module={["SYNC"]}>
                <ConnectionsSummary />
              </AuthorizedModule>
            ),
          },

          //Connection Details
          {
            path: "connection/details",
            element: (
              <AuthorizedModule module={["SYNC"]}>
                <Connection />
              </AuthorizedModule>
            ),
          },

          //Modelling Summary
          {
            path: "modelling",
            element: (
              <AuthorizedModule module={["MODEL"]}>
                <DataModelsSummary />
              </AuthorizedModule>
            ),
          },

          //Modelling Details
          {
            path: "modelling/details",
            element: (
              <AuthorizedModule module={["MODEL"]}>
                <DataModel />
              </AuthorizedModule>
            ),
          },

          //Analyse
          {
            path: "analyse",
            element: (
              <AuthorizedModule module={["ANALYSE"]}>
                <DataAnalysis />
              </AuthorizedModule>
            ),
          },

          //Governance Summary
          {
            path: "governance",
            element: (
              <AuthorizedModule module={["GOVERN"]}>
                <DataGovernanceSummary />
              </AuthorizedModule>
            ),
          },
          //Governance Details
          {
            path: "governance/details",
            element: (
              <AuthorizedModule module={["GOVERN"]}>
                <DataGovernanceDetails />
              </AuthorizedModule>
            ),
          },

          //Governance Explore
          {
            path: "governance/explore",
            element: (
              <AuthorizedModule module={["GOVERN"]}>
                <ObjectExplore />
              </AuthorizedModule>
            ),
          },

          //Operate Sunmmary
          {
            path: "operate",
            element: (
              <AuthorizedModule module={["PIPELINE", "MONITOR"]}>
                <DataPipelinesSummary />
              </AuthorizedModule>
            ),
          },

          //Operate Pipeline
          {
            path: "operate/pipeline",
            element: (
              <AuthorizedModule module={["PIPELINE"]}>
                <DataPipeline />
              </AuthorizedModule>
            ),
          },

          //Operate Monitor
          {
            path: "operate/monitor",
            element: (
              <AuthorizedModule module={["MONITOR"]}>
                <DataPipelineMonitor />
              </AuthorizedModule>
            ),
          },

          // Connect - Sources & Destinations
          {
            path: "connect",
            element: (
              <AuthorizedModule module={["SOURCE", "DESTINATION"]}>
                <Connect />
              </AuthorizedModule>
            ),
          },
          {
            path: "connect/sourcelist",
            element: (
              <AuthorizedModule module={["SOURCE"]}>
                <SourceList />
              </AuthorizedModule>
            ),
          },

          //Users & Roles
          {
            path: "userrole",
            element: (
              <AuthorizedModule module={["USER", "ROLE"]}>
                <UsersRoles />
              </AuthorizedModule>
            ),
          },

          //Migrate - Export & Import Objects
          {
            path: "migrate",
            element: (
              <AuthorizedModule module={["EXPORT", "IMPORT"]}>
                <Migrate />
              </AuthorizedModule>
            ),
          },

          //Business Packs
          {
            path: "bpacks",
            element: (
              <AuthorizedModule module={["BPACK"]}>
                <BusinessPacks />
              </AuthorizedModule>
            ),
          },
          {
            path: "bpacks/sapecc",
            element: (
              <AuthorizedModule module={["BPACK"]}>
                <SAPECCPack />
              </AuthorizedModule>
            ),
          },

          //Dashboards
          {
            path: "dashboard/dataprocess",
            element: (
              <AuthorizedModule module={["MONITOR"]}>
                <DataLoadDashboard />
              </AuthorizedModule>
            ),
          },
          {
            path: "dashboard/dataquality",
            element: (
              <AuthorizedModule module={["GOVERN"]}>
                <DataQualityDashboard />
              </AuthorizedModule>
            ),
          },
          {
            path: "dashboard/dataobjects",
            element: (
              <AuthorizedModule module={["HOME"]}>
                <DataObjectsDashboard />
              </AuthorizedModule>
            ),
          },
        ],
      },
    ],
  },
]);

export default router;
