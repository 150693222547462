export default function Dialog(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: theme.borders.borderRadius.lg,
          boxShadow: theme.boxShadows.xxl,
        },

        paperFullScreen: {
          borderRadius: 0,
        },
      },
    },
  };
}
