import { _uniqueId, orderBy } from "lodash";
import { PipeRunStatusColor } from "../constants/appconstant";
import {
  AGNCOLOR,
  CONNCOLOR,
  JONCOLOR,
  LKNCOLOR,
  MODLCOLOR,
  OTNCOLOR,
  SENCOLOR,
  SPNCOLOR,
  SRNCOLOR,
  STNCOLOR,
  STRTCOLOR,
  UNNCOLOR,
} from "../constants/appcolor";

import AWSLBG from "../assets/img/awslbg.png";
import AWSDBG from "../assets/img/awsdbg.png";
import AZURE from "../assets/img/azure.png";
import GCP from "../assets/img/gcp.png";
import SNOWFLAKE from "../assets/img/snowflake.png";
import ONPREMISE from "../assets/img/onpremise.png";
import CLICKHOUSE from "../assets/img/clickhouse.png";
import POSTGRES from "../assets/img/postgres.png";

//import { format as SQLFORMAT } from "sql-formatter";
import sqlFormatter from "sql-formatter-plus";
import dayjs from "dayjs";
import { Typography } from "@mui/material";

//Process the Schema Definition json to create a flat list of schema paramaters
//reorganise the source/destinatin schema connection parameters to display on screen

export const getSchemaConfigParam = (data) => {
  const schemaParam = [];
  const propKeys = Object.keys(data.properties);

  propKeys.forEach((propKey) => {
    const property = data.properties[propKey];
    const props = Object.keys(property);

    const isHidden =
      props.includes("airbyte_hidden") && property.airbyte_hidden;
    if (isHidden) return;

    if (property.type === "object" && !property.oneOf) {
      const objProps = Object.keys(property.properties);
      objProps.forEach((objProp) => {
        const propelem = { pname: objProp };

        if (property.order !== undefined) propelem.order = property.order;
        if (property.required?.includes(objProp)) propelem.required = true;

        Object.assign(propelem, property.properties[objProp]);
        schemaParam.push(propelem);
      });
    } else {
      const propelem = { pname: propKey, group: "zxdefault" };

      if (data.required?.includes(propKey)) propelem.required = true;

      props.forEach((key) => {
        propelem[key] = property[key];
        if (key === "group") {
          const availableGroup = data.groups?.find(
            (ag) => ag.id === property[key]
          );
          if (!availableGroup) propelem.group = "zxdefault";
        }
        if (key === "type" && property[key] === "object") {
          const { penum, pnestparam } = getSchemaNestedParam(property.oneOf);
          propelem.penum = penum;
          propelem.pnestparam = pnestparam;
        }
      });
      schemaParam.push(propelem);
    }
  });

  const orderedSchemaParam = orderBy(
    schemaParam,
    ["order", "required", "pname"],
    ["asc", "asc", "asc"]
  );

  const schemaDetails = orderedSchemaParam.reduce((details, item) => {
    let pval = "";

    if (item.penum) {
      pval = { [item.penum[0].attr]: item.penum[0].value };
    } else {
      pval =
        item.default !== undefined
          ? item.default
          : item.type === "boolean"
          ? true
          : item.type === "array"
          ? []
          : "";
    }

    if (item.title === undefined && item.const !== undefined) {
      pval = item.const;
    }

    details[item.pname] = pval;

    if (item.type === "object" && item.pnestparam) {
      item.pnestparam
        .filter((p) => p.pvalue === item.penum[0]?.value)
        .forEach((nitem) => {
          let npval = nitem.penum
            ? { [nitem.penum[0].attr]: nitem.penum[0].value }
            : nitem.default !== undefined
            ? nitem.default
            : nitem.type === "boolean"
            ? true
            : nitem.type === "array"
            ? []
            : "";
          details[item.pname][nitem.pname] = npval;
        });
    }

    return details;
  }, {});

  const paramgroup = data.groups
    ? [...data.groups, { id: "zxdefault", title: "Additional" }]
    : [{ id: "zxdefault", title: "" }];

  return {
    pgroups: paramgroup,
    params: orderedSchemaParam,
    details: schemaDetails,
  };
};

export const getSchemaNestedParam = (data) => {
  const penum = [];
  const pnestparam = [];
  const attrArray = [];
  const attrArrayOrd = [];

  data.forEach((item) => {
    const keys = Object.keys(item.properties);
    attrArray.push(keys);
    keys.forEach((k) =>
      attrArrayOrd.push({ key: k, order: item.properties[k].order })
    );
  });

  const commonKey = attrArray
    .shift()
    .find((v) => attrArray.every((a) => a.includes(v)));

  data.forEach((item) => {
    const keyObj = commonKey || item.required?.[0];
    const itemVal =
      item.properties[keyObj]?.default ?? item.properties[keyObj]?.const;

    penum.push({
      attr: keyObj,
      title: item.title,
      desc: item.desc,
      value: itemVal,
    });

    Object.keys(item.properties).forEach((propKey) => {
      if (propKey !== keyObj) {
        const propelem = {
          pname: propKey,
          pvalue: itemVal,
          attr: keyObj,
          ...item.properties[propKey],
        };

        if (propelem.type === "object" && item.properties[propKey]?.oneOf) {
          const nestedDetails = getSchemaNestedParam(
            item.properties[propKey].oneOf
          );
          propelem.penum = nestedDetails.penum;
          propelem.pnestparam = nestedDetails.pnestparam;
        }

        if (item.required?.includes(propKey)) propelem.required = true;

        pnestparam.push(propelem);
      }
    });
  });

  const orderedPnestparam = orderBy(
    pnestparam,
    ["order", "required", "pname"],
    ["asc", "asc", "asc"]
  );

  return { penum, pnestparam: orderedPnestparam };
};

export const getSchemaConfigParam_old = (data) => {
  //start with an empty array
  let schemaParam = [];

  //get all the properties keys
  const propKeys = Object.keys(data.properties);
  //loop through each key to flatten
  propKeys.forEach((propKey, index) => {
    //get the keys of the property
    const props = Object.keys(data.properties[propKey]);

    // check for hidden field and skip those entries
    const fldhdnIdx = props.findIndex((f) => f === "airbyte_hidden");
    let skipFld = false;
    if (fldhdnIdx !== -1 && data.properties[propKey]["airbyte_hidden"]) {
      skipFld = true;
    }

    if (!skipFld) {
      let propelem = {};

      if (
        data.properties[propKey].type === "object" &&
        data.properties[propKey].oneOf === undefined
      ) {
        const objpropkey = Object.keys(data.properties[propKey].properties);
        objpropkey.forEach((obj) => {
          let propelemp = {};
          propelemp.pname = obj;

          if (data.properties[propKey].order !== undefined) {
            propelemp.order = data.properties[propKey].order;
          }

          //check if this is a required property
          if (data.properties[propKey].required.find((elem) => elem === obj)) {
            propelemp.required = true;
          }
          const objprop = Object.keys(data.properties[propKey].properties[obj]);
          objprop.forEach((key) => {
            propelemp[key] = data.properties[propKey].properties[obj][key];
          });
          schemaParam.push(propelemp);
        });
      } else {
        //get the name of the property
        propelem.pname = propKey;

        //check if this is a required property
        if (data.required.find((elem) => elem === propKey)) {
          propelem.required = true;
        }

        propelem.group = "zxdefault";

        //loop through each property key
        props.forEach((key, index) => {
          propelem[key] = data.properties[propKey][key];

          if (key === "group") {
            ////check if the group is present in the group list
            const available_group = data.groups.find(
              (ag) => ag.id === data.properties[propKey][key]
            );
            if (available_group === undefined) {
              propelem.group = "zxdefault";
            }
          }

          //check if the property is a object with nested property
          if (key === "type" && data.properties[propKey][key] === "object") {
            //get the nested property
            const addObjDetails = getSchemaNestedParam(
              data.properties[propKey].oneOf
            );

            propelem.penum = addObjDetails.penum;
            propelem.pnestparam = addObjDetails.pnestparam;
          }
        });

        schemaParam.push(propelem);
      }
    }
  });

  schemaParam = orderBy(
    schemaParam,
    ["order", "required", "pname"],
    ["asc", "asc", "asc"]
  );

  //srcParam.sort((a, b) => a.order - b.order);

  //create a default value for the initial definition
  let schemaDetails = {};
  schemaParam.forEach(function (item, index) {
    let pval = "";

    if (item.penum !== undefined) {
      pval = { [item.penum[0].attr]: item.penum[0].value };
    } else {
      pval =
        item.default !== undefined
          ? item.default
          : item.type === "boolean"
          ? true
          : item.type === "array"
          ? []
          : "";
    }
    if (item.title === undefined) {
      pval = item.const !== undefined ? item.const : "";
    }
    schemaDetails[item.pname] = pval;

    if (item.type === "object") {
      item.pnestparam
        .filter((p) => p.pvalue === item.penum[0].value)
        .forEach((nitem) => {
          let npval = "";
          if (nitem.penum !== undefined) {
            npval = { [nitem.penum[0].attr]: nitem.penum[0].value };
          } else {
            npval =
              nitem.default !== undefined
                ? nitem.default
                : nitem.type === "boolean"
                ? true
                : nitem.type === "array"
                ? []
                : "";
          }
          schemaDetails[item.pname][nitem.pname] = npval;
        });
    }
  });

  let paramgroup = [];

  if (data.groups === undefined) {
    paramgroup = [{ id: "zxdefault", title: "" }];
  } else {
    paramgroup = [...data.groups, { id: "zxdefault", title: "Additional" }];
  }

  return { pgroups: paramgroup, params: schemaParam, details: schemaDetails };
};

export const getSchemaNestedParam_old = (data) => {
  let penum = []; //options for the parent properties
  let pnestparam = []; //nested parameter values

  //find out the same property to be the list of values
  let attrArray = [];
  let attrArrayOrd = [];

  data.forEach((item, index) => {
    const keys = Object.keys(item.properties);
    attrArray.push(keys);

    keys.forEach((k) => {
      attrArrayOrd.push({ key: k, order: item.properties[k].order });
    });
  });

  attrArrayOrd = orderBy(attrArrayOrd, "order");

  var result = attrArray.shift().reduce(function (res, v) {
    if (
      res.indexOf(v) === -1 &&
      attrArray.every(function (a) {
        return a.indexOf(v) !== -1;
      })
    )
      res.push(v);
    return res;
  }, []);

  //loop through the array of nested properties
  data.forEach((item, index) => {
    let opt = {}; //initial options for the paramter value

    /*     const item_val =
      item.properties[result[0]].default !== undefined
        ? item.properties[result[0]].default
        : item.properties[result[0]].const; */

    let keyObj;
    if (result.length === 1) {
      keyObj = result[0];
    } else {
      keyObj = item.required[0];
      //keyObj = attrArrayOrd[0].key;
    }

    const item_val =
      item.properties[keyObj].default !== undefined
        ? item.properties[keyObj].default
        : item.properties[keyObj].const;

    opt.attr = keyObj;
    opt.title = item.title;
    opt.desc = item.desc;
    opt.value = item_val;

    //get the keys of the paramater
    const propKeys = Object.keys(item.properties);
    //loop through the keys
    propKeys.forEach((propKey, index) => {
      let propelem = {};
      if (propKey !== keyObj) {
        propelem.pvalue = item_val;
        propelem.attr = keyObj;
        //get the name of the property
        propelem.pname = propKey;
        //check if this is a required property
        if (item.required !== undefined) {
          if (item.required.find((elem) => elem === propKey)) {
            propelem.required = true;
          }
        }
        const props = Object.keys(item.properties[propKey]);
        //loop through properties
        props.forEach((pKey, index) => {
          propelem[pKey] = item.properties[propKey][pKey];
          if (pKey === "type" && item.properties[propKey][pKey] === "object") {
            //get the nested property
            const addObjDetails = getDestNestedParams(
              item.properties[propKey].oneOf
            );
            propelem.penum = addObjDetails.penum;
            propelem.pnestparam = addObjDetails.pnestparam;
          }
        });
        pnestparam.push(propelem);
      }
    });
    penum.push(opt);
  });

  pnestparam = orderBy(
    pnestparam,
    ["order", "required", "pname"],
    ["asc", "asc", "asc"]
  );

  //pnestparam.sort((a, b) => a.order - b.order);

  return { penum: penum, pnestparam: pnestparam };
};

//Process the Source Definition json to create a flat list of source parameters
//reorganise the source system connection parameters to display on screen
export const getSourceConfigParam = (data) => {
  //start with an empty array
  let srcParam = [];

  //get all the properties keys
  const propKeys = Object.keys(data.properties);
  //loop through each key to flatten
  propKeys.forEach((propKey, index) => {
    let propelem = {};
    //get the name of the property
    propelem.pname = propKey;

    //check if this is a required property
    if (data.required.find((elem) => elem === propKey)) {
      propelem.required = true;
    }
    //get the keys of the property
    const props = Object.keys(data.properties[propKey]);

    const fldhdnIdx = props.findIndex((f) => f === "airbyte_hidden");
    let skipFld = false;
    if (fldhdnIdx !== -1 && data.properties[propKey]["airbyte_hidden"]) {
      skipFld = true;
    }

    if (!skipFld) {
      //loop through each property key
      props.forEach((key, index) => {
        propelem[key] = data.properties[propKey][key];

        //check if the property is a object with nested property
        if (key === "type" && data.properties[propKey][key] === "object") {
          //get the nested property
          const addObjDetails = getSourceAddParam(
            data.properties[propKey].oneOf || [
              data.properties[propKey].properties,
            ]
          );

          propelem.penum = addObjDetails.penum;
          propelem.pnestparam = addObjDetails.pnestparam;
        }
      });
    }

    srcParam.push(propelem);
  });

  srcParam = orderBy(
    srcParam,
    ["order", "required", "pname"],
    ["asc", "asc", "asc"]
  );

  //srcParam.sort((a, b) => a.order - b.order);

  //create a default value for the initial definition
  let srcDetails = {};
  srcParam.forEach(function (item, index) {
    let pval = "";

    if (item.penum !== undefined) {
      pval = { [item.penum[0].attr]: item.penum[0].value };
    } else {
      pval =
        item.default !== undefined
          ? item.default
          : item.type === "boolean"
          ? true
          : item.type === "array"
          ? []
          : "";
    }
    if (item.title === undefined) {
      pval = item.const;
    }
    srcDetails[item.pname] = pval;

    if (item.type === "object") {
      item.pnestparam.forEach((nitem) => {
        let npval = "";
        if (nitem.penum !== undefined) {
          npval = { [nitem.penum[0].attr]: nitem.penum[0].value };
        } else {
          npval =
            nitem.default !== undefined
              ? nitem.default
              : nitem.type === "boolean"
              ? true
              : nitem.type === "array"
              ? []
              : "";
        }
        srcDetails[item.pname][nitem.pname] = npval;
      });
    }
  });

  let paramgroup = [{ group: "general" }];
  if (data.groups !== undefined) {
    paramgroup = data.groups;
  }

  return { pgroups: paramgroup, params: srcParam, details: srcDetails };
};

export const getSourceAddParam = (data) => {
  let penum = []; //options for the parent properties
  let pnestparam = []; //nested parameter values

  //find out the same property to be the list of values
  let attrArray = [];
  data.forEach((item, index) => {
    const keys = Object.keys(item.properties);
    attrArray.push(keys);
  });

  var result = attrArray.shift().reduce(function (res, v) {
    if (
      res.indexOf(v) === -1 &&
      attrArray.every(function (a) {
        return a.indexOf(v) !== -1;
      })
    )
      res.push(v);
    return res;
  }, []);

  //loop through the array of nested properties
  data.forEach((item, index) => {
    let opt = {}; //initial options for the paramter value

    const item_val =
      item.properties[result[0]].default !== undefined
        ? item.properties[result[0]].default
        : item.properties[result[0]].const;

    opt.attr = result[0];
    opt.title = item.title;
    opt.desc = item.desc;
    opt.value = item_val;

    //get the keys of the paramater
    const propKeys = Object.keys(item.properties);
    //loop through the keys
    propKeys.forEach((propKey, index) => {
      let propelem = {};
      if (propKey !== result[0]) {
        propelem.pvalue = item_val;
        propelem.attr = result[0];
        //get the name of the property
        propelem.pname = propKey;
        //check if this is a required property
        if (item.required !== undefined) {
          if (item.required.find((elem) => elem === propKey)) {
            propelem.required = true;
          }
        }
        const props = Object.keys(item.properties[propKey]);
        //loop through properties
        props.forEach((pKey, index) => {
          propelem[pKey] = item.properties[propKey][pKey];
          if (pKey === "type" && item.properties[propKey][pKey] === "object") {
            //get the nested property
            const addObjDetails = getDestNestedParams(
              item.properties[propKey].oneOf
            );
            propelem.penum = addObjDetails.penum;
            propelem.pnestparam = addObjDetails.pnestparam;
          }
        });
        pnestparam.push(propelem);
      }
    });
    penum.push(opt);
  });

  pnestparam = orderBy(
    pnestparam,
    ["order", "required", "pname"],
    ["asc", "asc", "asc"]
  );

  //pnestparam.sort((a, b) => a.order - b.order);

  return { penum: penum, pnestparam: pnestparam };
};

//convert svg strings to images
export const getIconFromString = (svgStr) => {
  const svg = new Blob([svgStr], { type: "image/svg+xml" });
  const url = URL.createObjectURL(svg);
  return url;
};

//process the destination definition json to create a flat list of source parameters
// this is required to display parameters on screen
export const getDestConfigParam = (data) => {
  //start with an epty array
  let destParam = [];

  //get all the properties keys
  const propKeys = Object.keys(data.properties);
  //loop through each key to flatten
  propKeys.forEach((propKey, index) => {
    let propelem = {};
    // get the name of the property
    propelem.pname = propKey;

    //check if this is a required property
    if (data.required.find((elem) => elem === propKey)) {
      propelem.required = true;
    }
    //get the keys of the property
    const props = Object.keys(data.properties[propKey]);

    const fldhdnIdx = props.findIndex((f) => f === "airbyte_hidden");
    let skipFld = false;
    if (fldhdnIdx !== -1 && data.properties[propKey]["airbyte_hidden"]) {
      skipFld = true;
    }

    if (!skipFld) {
      //loop through each property key
      props.forEach((key, index) => {
        propelem[key] = data.properties[propKey][key];

        //check if the property is a object with nested property
        if (key === "type" && data.properties[propKey][key] === "object") {
          //get the nested property
          const addObjDetails = getDestNestedParams(
            data.properties[propKey].oneOf || [
              data.properties[propKey].properties,
            ]
          );

          propelem.penum = addObjDetails.penum;
          propelem.pnestparam = addObjDetails.pnestparam;
        }
      });
    }

    destParam.push(propelem);
  });

  destParam = orderBy(
    destParam,
    ["order", "required", "pname"],
    ["asc", "asc", "asc"]
  );
  //destParam.sort((a, b) => a.order - b.order);

  //create a deafult value for the initial definition
  let objDefault = {};
  destParam.forEach((item, index) => {
    let pval = "";
    if (item.penum !== undefined) {
      pval = { [item.penum[0].attr]: item.penum[0].value };
    } else {
      pval =
        item.default !== undefined
          ? item.default
          : item.type === "boolean"
          ? true
          : item.type === "array"
          ? []
          : "";
    }
    if (item.title === undefined) {
      pval = item.const;
    }
    objDefault[item.pname] = pval;

    if (item.type === "object") {
      item.pnestparam.forEach((nitem) => {
        let npval = "";
        if (nitem.penum !== undefined) {
          npval = { [nitem.penum[0].attr]: nitem.penum[0].value };
        } else {
          npval =
            nitem.default !== undefined
              ? nitem.default
              : nitem.type === "boolean"
              ? true
              : nitem.type === "array"
              ? []
              : "";
        }
        objDefault[item.pname][nitem.pname] = npval;
      });
    }
  });

  let paramgroup = [{ group: "general" }];
  if (data.groups !== undefined) {
    paramgroup = data.groups;
  }

  return { pgroups: paramgroup, params: destParam, details: objDefault };
};

const getDestNestedParams = (data) => {
  let penum = []; //options for the parent properties
  let pnestparam = []; //nested parameter values

  //find out the same property to be the list of values
  let attrArray = [];
  data.forEach((item, index) => {
    const keys = Object.keys(item.properties);
    attrArray.push(keys);
  });

  var result = attrArray.shift().reduce(function (res, v) {
    if (
      res.indexOf(v) === -1 &&
      attrArray.every(function (a) {
        return a.indexOf(v) !== -1;
      })
    )
      res.push(v);
    return res;
  }, []);

  //loop through the array of nested properties
  data.forEach((item, index) => {
    let opt = {}; //inital options for the paramter value
    const item_val =
      item.properties[result[0]].default !== undefined
        ? item.properties[result[0]].default
        : item.properties[result[0]].const;
    opt.attr = result[0];
    opt.title = item.title;
    opt.desc = item.desc;
    opt.value = item_val;

    //get the keys of the parameters
    const propKeys = Object.keys(item.properties);
    //loop through the keys
    propKeys.forEach((propKey, index) => {
      let propelem = {};

      if (propKey !== result[0]) {
        propelem.pvalue = item_val;
        propelem.attr = result[0];
        //get the name of the property
        propelem.pname = propKey;
        //check if this is a required property
        if (item.required !== undefined) {
          if (item.required.find((elem) => elem === propKey)) {
            propelem.required = true;
          }
        }
        const props = Object.keys(item.properties[propKey]);
        //loop through properties

        props.forEach((pKey, index) => {
          propelem[pKey] = item.properties[propKey][pKey];
          if (pKey === "type" && item.properties[propKey][pKey] === "object") {
            //get the nested property
            const addObjDetails = getDestNestedParams(
              item.properties[propKey].oneOf
            );
            propelem.penum = addObjDetails.penum;
            propelem.pnestparam = addObjDetails.pnestparam;
          }
        });
        pnestparam.push(propelem);
      }
    });
    penum.push(opt);
  });

  pnestparam = orderBy(
    pnestparam,
    ["order", "required", "pname"],
    ["asc", "asc", "asc"]
  );

  return { penum: penum, pnestparam: pnestparam };
};

export const getRandomId = (name) => {
  return _uniqueId(name);
};

///function to get tables/streams from the source discover-schema
export const getSourceStreams = (catalog) => {
  //create an array of source streams/tables
  //{srcschema, srcentity, fields:{fieldname, datatype}

  let src_obj = [];

  catalog.streams.forEach((item, idx) => {
    let stream_obj = {};
    stream_obj.srcSchema = item.stream.namespace;
    stream_obj.srcEntity = item.stream.name;
    stream_obj.fields = [];
    const x = Object.keys(item.stream.jsonSchema.properties);
    x.forEach((fld, idx) => {
      let field = {};
      field.fieldname = fld;
      field.datatype =
        item.stream.jsonSchema.properties[fld].airbyte_type ||
        item.stream.jsonSchema.properties[fld].type;
      stream_obj.fields.push(field);
    });
    stream_obj.fields.sort((a, b) =>
      a.fieldname > b.fieldname ? 1 : b.fieldname > a.fieldname ? -1 : 0
    );
    stream_obj.supportedSyncModes = item.stream.supportedSyncModes;
    stream_obj.defaultCursorField = item.stream.defaultCursorField;
    stream_obj.sourceDefinedPrimaryKey = item.stream.sourceDefinedPrimaryKey;

    src_obj.push(stream_obj);
  });
  src_obj.sort((a, b) =>
    a.srcEntity > b.srcEntity ? 1 : b.srcEntity > a.srcEntity ? -1 : 0
  );
  return src_obj;
};

export const convertBytesToUnit = (bytes) => {
  const b = Number(bytes).toFixed(1);
  const k = Number(bytes / 1024).toFixed(1);
  const m = Number(bytes / 1024 / 1024).toFixed(1);
  const g = Number(bytes / 1024 / 1024 / 1024).toFixed(1);
  const t = Number(bytes / 1024 / 1024 / 1024 / 1024).toFixed(1);

  let dlVal = 0;
  if (t > 1) {
    dlVal = t + " TB";
  } else if (g > 1) {
    dlVal = g + " GB";
  } else if (m > 1) {
    dlVal = m + " MB";
  } else if (k > 1) {
    dlVal = k + " KB";
  } else {
    dlVal = b + " B";
  }

  return dlVal;
};

/// returns the pipeline Monitor status color based on state
export const getPipeStateColor = (state) => {
  return PipeRunStatusColor.find((f) => f.state === state)?.color;
};

export const getNodeColorbyType = (nodeType) => {
  let ncolor = "#fff";
  switch (nodeType) {
    case "SRN":
      ncolor = SRNCOLOR;
      break;
    case "LKN":
      ncolor = LKNCOLOR;
      break;
    case "JON":
      ncolor = JONCOLOR;
      break;
    case "STN":
      ncolor = STNCOLOR;
      break;
    case "UNN":
      ncolor = UNNCOLOR;
      break;
    case "AGN":
      ncolor = AGNCOLOR;
      break;
    case "SEN":
      ncolor = SENCOLOR;
      break;
    case "OTN":
      ncolor = OTNCOLOR;
      break;
    case "SPN":
      ncolor = SPNCOLOR;
      break;
    case "STRT":
      ncolor = STRTCOLOR;
      break;
    case "CONN":
      ncolor = CONNCOLOR;
      break;
    case "MODL":
      ncolor = MODLCOLOR;
      break;
    default:
      ncolor = "#fff";
  }
  return ncolor;
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name, theme) => {
  return {
    children: (
      <Typography sx={{ color: theme?.palette.common.white }}>
        {`${name.split(" ")[0][0]}${name.split(" ")[1][0]}`}
      </Typography>
    ),
  };
};

export const excode = sqlFormatter.format(
  "with customer_orders as (select customer_id,min(order_date) as first_order_date,max(order_date) as most_recent_order_date,count(order_id) as number_of_orders from jaffle_shop.orders group by 1) select customers.customer_id,customers.first_name,customers.last_name,customer_orders.first_order_date,customer_orders.most_recent_order_date,coalesce(customer_orders.number_of_orders, 0) as number_of_orders from jaffle_shop.customers left join customer_orders using (customer_id)"
);

export const getSubsCloudTypeIcon = (ctype, theme) => {
  if (ctype === "aws") {
    return theme.palette.mode === "light" ? (
      <img src={AWSLBG} alt="AWS" style={{ height: 20 }} />
    ) : (
      <img src={AWSDBG} alt="AWS" style={{ height: 20 }} />
    );
  } else if (ctype === "azure") {
    return <img src={AZURE} alt="Azure" style={{ height: 20 }} />;
  } else if (ctype === "gcp") {
    return <img src={GCP} alt="GCP" style={{ height: 20 }} />;
  } else if (ctype === "snowflake") {
    return <img src={SNOWFLAKE} alt="Snowflake" style={{ height: 20 }} />;
  } else if (ctype === "local") {
    return <img src={ONPREMISE} alt="Local" style={{ height: 20 }} />;
  } else if (ctype === "clickhouse") {
    return <img src={CLICKHOUSE} alt="Local" style={{ height: 20 }} />;
  } else if (ctype === "postgres") {
    return <img src={POSTGRES} alt="Local" style={{ height: 20 }} />;
  } else return ctype;
};

export const isDateOutOfRange = (stdate, eddate) => {
  let isExp = false;
  const st_date = dayjs(stdate);
  const ed_date = dayjs(eddate);

  isExp = dayjs().isBefore(st_date, "day") || dayjs().isAfter(ed_date, "day");

  return isExp;
};
