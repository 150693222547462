import { Fade } from "@mui/material";
import pxToRem from "../functions/pxToRem";

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        TransitionComponent: Fade,
      },
      styleOverrides: {
        tooltip: {
          maxWidth: pxToRem(200),
          backgroundColor: theme.palette.black.main,
          color: theme.palette.light.state,
          fontSize: theme.typography.body2,
          textAlign: "center",
          borderRadius: theme.borders.borderRadius.md,
          opacity: 0.7,
          padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,
        },

        arrow: {
          color: theme.palette.black.main,
        },
      },
    },
  };
}
