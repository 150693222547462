import { blue } from "@mui/material/colors";

export default function Drawer(theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor:
            theme.palette.mode === "light" ? blue[800] : "#16131B",
        },
      },
    },
  };
}
