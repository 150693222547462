//React
import { useState } from "react";

//MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

//ICONS
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import uxliLogob from "../../assets/logo/xgenlogob.png";
import uxliLogow from "../../assets/logo/xgenlogow.png";

//3rd-Party
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Local
import ToastNotify from "../../components/Common/ToastNotify";
import { BorderLinearProgress } from "../../components/Styled/StyledComponent";

//Components

//Recoil State

//API Service
import Auth from "../../services/auth.service";
import Session from "../../services/session.service";

//Const

export default function Login() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //check if user password is initial and needs to be changed in first login
  const [userValid, setUserValid] = useState(false);
  const [initialPassword, setInitialPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [showChangePassDialog, setShowChangePassDialog] = useState(false);
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirmpassword: "",
  });
  const [passwordStrength, setPasswordStrength] = useState("");

  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [selectedSubs, setSelectedSubs] = useState("");

  const checkPasswordStrength = (pval) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    const mediumRegex = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );

    if (strongRegex.test(pval)) {
      setPasswordStrength("STRONG");
    } else if (mediumRegex.test(pval)) {
      setPasswordStrength("MEDIUM");
    } else setPasswordStrength("WEAK");
  };

  //////////////////UI Functions //////////////////////

  // track the selected subscription to login
  const handleSubsChange = (e) => {
    setSelectedSubs(e.target.value);
    handleUserSubscriptionLogin(e.target.value);
  };

  const handleChangePassDiaClose = () => {
    setShowChangePassDialog(false);
    setUserValid(false);
    setInitialPassword(false);
    setNewPassword({
      password: "",
      confirmpassword: "",
    });
    setPasswordStrength("");
  };

  const handleChangePassInput = (e) => {
    const { name, value } = e.target;
    setNewPassword({ ...newPassword, [name]: value });
    if (value === "") {
      setPasswordStrength("");
    } else {
      checkPasswordStrength(value);
    }
  };

  ///////Login API//////

  const handleUserSubscriptionLogin = async (subsid) => {
    setIsLoading(true);
    try {
      const resp = await Auth.usersubslogin(subsid);
      let access = {};

      //const cuser = Session.getUser();

      access = resp.data.useraccess;

      const subsdetails = {
        subsid: subsid,
        subsname: userSubscriptions.find(
          (doc) => doc.subscription_id === subsid
        ).subscription_name,
        access: access,
      };

      Session.setCurrentSubs(subsdetails);

      setIsLoading(false);
      if (state) {
        navigate(state.path);
      } else {
        navigate("/");
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(
        <ToastNotify
          title="Subscription Login Error"
          detail={err.response.data.message}
        />
      );
    }
  };

  const handleUserAuthentication = async (e) => {
    e?.preventDefault();
    setIsLoading(true);

    try {
      const resp = await Auth.authenticate(loginEmail, loginPassword);
      if (
        resp.data.subscription_list === undefined &&
        resp.data.user.initial_password
      ) {
        setUserValid(true);
        setUserDetails(resp.data.user);
        setInitialPassword(true);
        setShowChangePassDialog(true);
      } else {
        setUserValid(true);
        setInitialPassword(false);
        setUserSubscriptions(resp.data.subscription_list);
        Session.setUser(resp.data.user, resp.data.subscription_list);
      }

      setIsLoading(false);
    } catch (err) {
      setUserValid(false);
      setIsLoading(false);
      toast.error(
        err.response ? (
          <ToastNotify
            title={err.response?.data.message || err.message}
            detail={err.response?.data.details || ""}
          />
        ) : (
          <ToastNotify
            title="Cannot Login into the system"
            detail="Backend server error. Please contact your admin support."
          />
        )
      );
    }
  };

  const handleChangeInitialPassword = async (e) => {
    e.preventDefault();

    //validate if the new and confirm password matches and not blank

    if (newPassword.password === "" || newPassword.confirmpassword === "") {
      toast.error("Password cannot be blank.");
      return;
    }
    if (newPassword.password !== newPassword.confirmpassword) {
      toast.error("New Password and Confirm Password does not match.");
      return;
    }

    try {
      const user = {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        avatar: "",
        password: newPassword.password,
      };
      const resp = Auth.userChangeInitialPassword(user);
      toast.success(
        <ToastNotify
          title={(await resp).data.message}
          detail="Please login with new password."
        />
      );
      handleChangePassDiaClose();
    } catch (err) {
      toast.error(
        <ToastNotify
          title="Subscription Login Error"
          detail={err.response?.data.message}
        />
      );
    }
    handleChangePassDiaClose();
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        sx={{ height: 1, width: 1 }}
      >
        <Card
          variant="standard"
          sx={{
            width: "60%",
            borderRadius: 3,
            backgroundColor: theme.palette.mode === "light" ? "#fff" : "",
          }}
        >
          <CardContent>
            {/* Header Logo and Text */}
            {/*  <Box
              display="inline-flex"
              sx={{
                width: 1,
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <img src={XGENLogo} alt="logo" height="65" width="65" />
              <Typography variant="h4" sx={{ ml: 2, fontWeight: 700 }}>
                Unified Data Management
              </Typography>
            </Box> */}

            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
                mt: 5,
                mb: 10,
              }}
            >
              {theme.palette.mode === "dark" ? (
                <img src={uxliLogow} alt="logo" height="85" />
              ) : (
                <img src={uxliLogob} alt="logo" height="85" />
              )}
            </Box>

            {/* Form to provide username and password. */}
            <form onSubmit={handleUserAuthentication}>
              <Grid>
                <Stack
                  spacing={2}
                  sx={{
                    minWidth: "20rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="h6">
                      Log into your xGEN account
                    </Typography>
                  </Box>

                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    disabled={userSubscriptions.length > 0}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <InputLabel htmlFor="outlined-adornment-email">
                      Username
                    </InputLabel>
                    <OutlinedInput
                      className="inputbox"
                      id="outlined-adornment-email"
                      type="text"
                      value={loginEmail}
                      onChange={(event) => {
                        setLoginEmail(event.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <PersonOutlineIcon />
                        </InputAdornment>
                      }
                      label="Username"
                      sx={{
                        "& .Mui-focused": { borderColor: "white" },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0.5,
                        },
                      }}
                    />
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    disabled={userSubscriptions.length > 0}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>

                    <OutlinedInput
                      id="outlined-adornment-password"
                      className="inputbox"
                      type={showPassword ? "text" : "password"}
                      value={loginPassword}
                      onChange={(event) => {
                        setLoginPassword(event.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleUserAuthentication();
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleUserAuthentication();
                              }
                            }}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0.5,
                        },
                      }}
                    />
                  </FormControl>
                  <Box></Box>
                  {Auth.isAuthenticated() && userSubscriptions.length !== 0 ? (
                    <></>
                  ) : (
                    <>
                      <Typography variant="caption">
                        Forgot Password? Please contact your system
                        administrator for password reset.
                      </Typography>

                      <Button
                        sx={{
                          border: 0,
                          borderRadius: 10,
                          //boxShadow: "0 3px 5px 2px rgba(13, 114, 185, .3)",
                          color: "white",
                          padding: "0 30px",
                          mt: "20px",
                          minWidth: "12rem",
                          height: "2.5rem",
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isLoading}
                      >
                        Login
                      </Button>
                    </>
                  )}
                </Stack>
              </Grid>
            </form>

            {/* Once authenticated Subscription selection  to login */}
            {userSubscriptions?.length > 0 && (
              <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
                <InputLabel>Select Subscription</InputLabel>
                <Select
                  id="select_subs"
                  value={selectedSubs}
                  onChange={handleSubsChange}
                  label="Select Subscription"
                >
                  {userSubscriptions?.map((subs) => (
                    <MenuItem
                      key={
                        subs.account_id +
                        subs.subscription_name +
                        subs.subscription_id
                      }
                      dense
                      value={subs.subscription_id}
                    >
                      {subs.subscription_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {Auth.isAuthenticated() &&
              userSubscriptions?.length === 0 &&
              userValid &&
              !initialPassword && (
                <Typography color="error" sx={{ mt: 2 }}>
                  No Subscription assigned to user. Please check with your
                  administrator
                </Typography>
              )}

            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
                mt: 5,
              }}
            >
              <Typography variant="caption" sx={{ mt: 1 }}>
                Need help? Reach us at support@uxli.co.uk
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {/* Change Initial Password Dialog */}
      <Dialog
        open={showChangePassDialog}
        onClose={handleChangePassDiaClose}
        sx={{
          "& .MuiDialog-paper": {
            width: 400,
            maxHeight: 500,
          },
        }}
      >
        <DialogTitle>
          <Typography>Change Initial Password</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleChangeInitialPassword}>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <FormLabel>
                <Box
                  display="inline-flex"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <Typography>{`${userDetails.first_name} ${userDetails.last_name}`}</Typography>
                  <Typography>{userDetails.user_id}</Typography>
                </Box>
              </FormLabel>
              <br></br>

              <TextField
                size="small"
                label="New Password"
                required
                type="password"
                name="password"
                value={newPassword.password}
                onChange={handleChangePassInput}
              />
              <Box>
                <BorderLinearProgress
                  mode={passwordStrength}
                  variant="determinate"
                  value={
                    passwordStrength === "STRONG"
                      ? 90
                      : passwordStrength === "MEDIUM"
                      ? 60
                      : passwordStrength === "WEAK"
                      ? 10
                      : 0
                  }
                />
                <FormLabel sx={{ mt: 0 }}>
                  <Typography variant="caption">Password Strength</Typography>
                </FormLabel>
              </Box>

              <TextField
                size="small"
                label="Confirm Password"
                required
                error={newPassword.password !== newPassword.confirmpassword}
                helperText="Passwords should match"
                type="password"
                name="confirmpassword"
                value={newPassword.confirmpassword}
                onChange={handleChangePassInput}
              />

              <Box>
                <Button type="submit">Change Password</Button>
              </Box>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangePassDiaClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
