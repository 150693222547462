//// Unselected Icons
import HomeIcon from "@mui/icons-material/HomeOutlined";
import DataSyncIcon from "@mui/icons-material/Air";
import DataModelIcon from "@mui/icons-material/ViewInArOutlined";
import AnalyticsIcon from "@mui/icons-material/AnalyticsOutlined";
import DataGovernanceIcon from "@mui/icons-material/AccountBalanceOutlined";
import DataOperateIcon from "@mui/icons-material/AccountTreeOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccountsOutlined";
import ConnectIcon from "@mui/icons-material/HubOutlined";
import BPackIcon from "@mui/icons-material/BusinessCenterOutlined";
import MigrateIcon from "@mui/icons-material/LayersOutlined";

//// Selected Icons
import HomeIconSelected from "@mui/icons-material/Home";

import DataModelIconSelected from "@mui/icons-material/ViewInAr";
import AnalyticsIconSelected from "@mui/icons-material/Analytics";
import DataGovernanceIconSelected from "@mui/icons-material/AccountBalance";
import DataOperateIconSelected from "@mui/icons-material/AccountTree";

import ConnectIconSelected from "@mui/icons-material/Hub";
import ManageAccountsIconSelected from "@mui/icons-material/ManageAccounts";
import MigrateIconSelected from "@mui/icons-material/Layers";
import BPackIconSelected from "@mui/icons-material/BusinessCenter";

export const sideF1Menu = [
  {
    id: 100,
    navto: "/",
    unsel_icon: <HomeIcon sx={{ fontSize: 20 }} />,
    sel_icon: <HomeIconSelected sx={{ fontSize: 20 }} />,
    stext: "Home",
    desc: "Home",
    module: ["HOME"],
  },
  {
    id: 200,
    navto: "/connection",
    unsel_icon: <DataSyncIcon sx={{ fontSize: 20 }} />,
    sel_icon: <DataSyncIcon sx={{ fontSize: 20 }} />,
    stext: "Sync",
    desc: "Sync",
    module: ["SYNC"],
  },
  {
    id: 300,
    navto: "/modelling",
    unsel_icon: <DataModelIcon sx={{ fontSize: 20 }} />,
    sel_icon: <DataModelIconSelected sx={{ fontSize: 20 }} />,
    stext: "Model",
    desc: "Model",
    module: ["MODEL"],
  },
  {
    id: 400,
    navto: "/analyse",
    unsel_icon: <AnalyticsIcon sx={{ fontSize: 20 }} />,
    sel_icon: <AnalyticsIconSelected sx={{ fontSize: 20 }} />,
    stext: "Analyse",
    desc: "Analysis",
    module: ["ANALYSE"],
  },

  {
    id: 500,
    navto: "/governance",
    unsel_icon: <DataGovernanceIcon sx={{ fontSize: 20 }} />,
    sel_icon: <DataGovernanceIconSelected sx={{ fontSize: 20 }} />,
    stext: "Govern",
    desc: "Govern",
    module: ["GOVERN"],
  },
  {
    id: 600,
    navto: "/operate",
    unsel_icon: <DataOperateIcon sx={{ fontSize: 20 }} />,
    sel_icon: <DataOperateIconSelected sx={{ fontSize: 20 }} />,
    stext: "Pipeline",
    desc: "Pipeline",
    module: ["PIPELINE", "MONITOR"],
  },
];

export const sideF2Menu = [
  {
    id: 1000,
    navto: "/connect",
    state: { showTab: "SRC" },
    unsel_icon: <ConnectIcon sx={{ fontSize: 20 }} />,
    sel_icon: <ConnectIconSelected sx={{ fontSize: 20 }} />,
    stext: "Connect",
    desc: "Connect",
    module: ["SOURCE", "DESTINATION"],
  },
  {
    id: 2000,
    navto: "/userrole",
    unsel_icon: <ManageAccountsIcon sx={{ fontSize: 20 }} />,
    sel_icon: <ManageAccountsIconSelected sx={{ fontSize: 20 }} />,
    stext: "Users",
    desc: "Users & Roles",
    module: ["USER", "ROLE"],
  },
  {
    id: 3000,
    navto: "/migrate",
    unsel_icon: <MigrateIcon sx={{ fontSize: 20 }} />,
    sel_icon: <MigrateIconSelected sx={{ fontSize: 20 }} />,
    stext: "Migrate",
    desc: "Migrate",
    module: ["EXPORT", "IMPORT"],
  },
  {
    id: 4000,
    navto: "/bpacks",
    unsel_icon: <BPackIcon sx={{ fontSize: 20 }} />,
    sel_icon: <BPackIconSelected sx={{ fontSize: 20 }} />,
    stext: "B.Packs",
    desc: "Business Packs",
    module: ["BPACK"],
  },
];

export const appModules = [
  "HOME",
  "SYNC",
  "MODEL",
  "ANALYSE",
  "GOVERN",
  "PIPELINE",
  "MONITOR",
  "SOURCE",
  "DESTINATION",
  "USER",
  "ROLE",
  "EXPORT",
  "IMPORT",
  "BPACK",
];

export const appModuleAccessList = [
  {
    modulename: "HOME",
    READ: true,
  },
  {
    modulename: "SYNC",
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    modulename: "MODEL",
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    modulename: "ANALYSE",
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    modulename: "GOVERN",
    CREATE: false,
    READ: false,
    UPDATE: false,
  },
  {
    modulename: "PIPELINE",
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    modulename: "MONITOR",
    READ: false,
    UPDATE: false,
  },
  {
    modulename: "SOURCE",
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    modulename: "DESTINATION",
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    modulename: "USER",
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    modulename: "ROLE",
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  {
    modulename: "EXPORT",
    CREATE: false,
  },
  {
    modulename: "IMPORT",
    CREATE: false,
  },
  {
    modulename: "BPACK",
    CREATE: false,
  },
];

export const accessModuleGroup = [
  {
    group: "Engineering",
    accessrole: ["SYNC", "MODEL", "PIPELINE", "MONITOR"],
  },

  {
    group: "System",
    accessrole: ["SOURCE", "DESTINATION", "EXPORT", "IMPORT"],
  },

  {
    group: "Governance",
    accessrole: ["GOVERN", "USER", "ROLE"],
  },
  {
    group: "Analysis",
    accessrole: ["ANALYSE"],
  },

  {
    group: "Package",
    accessrole: ["BPACK"],
  },
];
