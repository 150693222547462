const setUser = (user_data, usersubs_data) => {
  sessionStorage.setItem("user", JSON.stringify(user_data));
  sessionStorage.setItem("usersubs", JSON.stringify(usersubs_data));
};

const setCurrentSubs = (currentsubs) => {
  sessionStorage.setItem("currentsubs", JSON.stringify(currentsubs));
};

const getUser = () => {
  return JSON.parse(sessionStorage.getItem("user"));
};

const getCurrentSubs = () => {
  return JSON.parse(sessionStorage.getItem("currentsubs"));
};

const getSubsDestType = () => {
  const subs = JSON.parse(sessionStorage.getItem("usersubs"));
  const cursubs = JSON.parse(sessionStorage.getItem("currentsubs"));

  return subs.find((s) => s.subscription_id === cursubs.subsid)?.cloud_type;
};

const getUserSubsList = () => {
  return JSON.parse(sessionStorage.getItem("usersubs"));
};

const closeSession = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("usersubs");
  sessionStorage.removeItem("currentsubs");
};

const setSupersetToken = (token) => {
  sessionStorage.setItem("sstoken", JSON.stringify(token));
};

const getSupersetToken = () => {
  return JSON.parse(sessionStorage.getItem("sstoken"));
};

const getModuleAccess = (module) => {
  const currentSubs = JSON.parse(sessionStorage.getItem("currentsubs"));
  return currentSubs?.access.role.find((r) => r.modulename === module);
};

const getSubsId = () => {
  const currentSubs = JSON.parse(sessionStorage.getItem("currentsubs"));
  return currentSubs.subsid;
};

const Session = {
  setUser,
  setCurrentSubs,
  getUser,
  getCurrentSubs,
  getUserSubsList,
  closeSession,
  setSupersetToken,
  getSupersetToken,
  getModuleAccess,
  getSubsId,
  getSubsDestType,
};

export default Session;
