export default function TableContainer(theme) {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.card,
          boxShadow: theme.boxShadows.md,
          borderRadius: theme.borders.borderRadius.xl,
        },
      },
    },
  };
}
