//React
import React from "react";

//MUI

//ICONS

//3rd Party
import ReactDOM from "react-dom/client";

//CSS
import "./css/App.css";

//Local

//Components
import App from "./App";

//Recoil State

//API Service

//Const

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
