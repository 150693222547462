import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";

import { ColorModeContext } from "../context/color-context";

export default function SubHeaderNavigation() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <IconButton onClick={colorMode.toggleColorMode}>
      {theme.palette.mode === "dark" ? (
        <Tooltip title="Turn on the light">
          <LightModeOutlinedIcon />
        </Tooltip>
      ) : (
        <Tooltip title="Turn off the light">
          <DarkModeOutlinedIcon sx={{ color: theme.palette.common.white }} />
        </Tooltip>
      )}
    </IconButton>
  );
}
