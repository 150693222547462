import {
  MONDEFFERED,
  MONFAILED,
  MONNULL,
  MONQUEUE,
  MONREMOVED,
  MONRESTARTING,
  MONRUNNING,
  MONSCHEDULED,
  MONSKIPPED,
  MONSUCCESS,
  MONUPFORRESCHEDULE,
  MONUPFORRETRY,
  MONUPSTREAMFAILED,
} from "./appcolor";

export const XGEN_APP_VERSION = "3.0.6";

export const DRAWER_WIDTH = 180;
export const APPBAR_HEIGHT = 40;
export const SPINNER_COLOR = "#63DEC3";
export const ROW_SIZE = 48;

export const SuperRole = [
  {
    modulename: "Home",
    moduleaccess: { CREATE: true, READ: true, UPDATE: true, DELETE: true },
  },
  {
    modulename: "DataSync",
    moduleaccess: { CREATE: true, READ: true, UPDATE: true, DELETE: true },
  },
  {
    modulename: "DataModel",
    moduleaccess: { CREATE: true, READ: true, UPDATE: true, DELETE: true },
  },
  {
    modulename: "DataExplore",
    moduleaccess: { CREATE: true, READ: true, UPDATE: true, DELETE: true },
  },
  {
    modulename: "DataOperate",
    moduleaccess: { CREATE: true, READ: true, UPDATE: true, DELETE: true },
  },
  {
    modulename: "Settings",
    moduleaccess: { CREATE: true, READ: true, UPDATE: true, DELETE: true },
  },
];

export const nodeFilterOperatorList = [
  { value: "=", desc: "Equal", from: true, to: false },
  { value: "<>", desc: "Not Equal", from: true, to: false },
  { value: "BETWEEN", desc: "Between", from: true, to: true },
  { value: "IN", desc: "List of Values", from: true, to: false },
  { value: "NOT IN", desc: "Not in List", from: true, to: false },
  { value: "IS NULL", desc: "Is Null", from: false, to: false },
  { value: "IS NOT NULL", desc: "Is Not Null", from: false, to: false },
  { value: "<", desc: "Less than", from: true, to: false },
  { value: "<=", desc: "Less than or Equal to", from: true, to: false },
  { value: ">", desc: "Greater than", from: true, to: false },
  { value: ">=", desc: "Greater than or Equal to", from: true, to: false },
  { value: "LIKE", desc: "Contains pattern", from: true, to: false },
];

export const PipeRunStatusColor = [
  { state: "deferred", color: MONDEFFERED },
  { state: "failed", color: MONFAILED },
  { state: "queued", color: MONQUEUE },
  { state: "removed", color: MONREMOVED },
  { state: "restarting", color: MONRESTARTING },
  { state: "running", color: MONRUNNING },
  { state: "scheduled", color: MONSCHEDULED },
  { state: "shutdown", color: MONREMOVED },
  { state: "skipped", color: MONSKIPPED },
  { state: "success", color: MONSUCCESS },
  { state: "up_for_reschedule", color: MONUPFORRESCHEDULE },
  { state: "up_for_retry", color: MONUPFORRETRY },
  { state: "upstream_failed", color: MONUPSTREAMFAILED },
  { state: null, color: MONNULL },
];

/* export const dwDataType = [
  { db_type: "character varying", ui_type: "varchar", ui_fldtype: "A" },
  { db_type: "varchar", ui_type: "varchar", ui_fldtype: "A" },
  { db_type: "text", ui_type: "varchar", ui_fldtype: "A" },
  { db_type: "string", ui_type: "string", ui_fldtype: "A" },
  { db_type: "nvarchar", ui_type: "varchar", ui_fldtype: "A" },

  { db_type: "boolean", ui_type: "boolean", ui_fldtype: "A" },

  { db_type: "date", ui_type: "date", ui_fldtype: "D" },
  { db_type: "datetime", ui_type: "timestamp", ui_fldtype: "D" },

  {
    db_type: "timestamp with time zone",
    ui_type: "timestamp",
    ui_fldtype: "D",
  },
  {
    db_type: "timestamp without time zone",
    ui_type: "timestamp",
    ui_fldtype: "D",
  },
  {
    db_type: "timestamp",
    ui_type: "timestamp",
    ui_fldtype: "D",
  },
  { db_type: "TIMESTAMP_TZ", ui_type: "timestamp", ui_fldtype: "D" },

  { db_type: "int64", ui_type: "integer", ui_fldtype: "M" },
  { db_type: "bigint", ui_type: "bigint", ui_fldtype: "M" },
  { db_type: "INT64", ui_type: "bigint", ui_fldtype: "M" },
  { db_type: "UInt8", ui_type: "integer", ui_fldtype: "M" },
  { db_type: "UInt16", ui_type: "integer", ui_fldtype: "M" },
  { db_type: "UInt32", ui_type: "integer", ui_fldtype: "M" },
  { db_type: "UInt128", ui_type: "integer", ui_fldtype: "M" },
  { db_type: "UInt256", ui_type: "integer", ui_fldtype: "M" },
  { db_type: "integer", ui_type: "integer", ui_fldtype: "M" },
  { db_type: "smallint", ui_type: "integer", ui_fldtype: "M" },

  { db_type: "double precision", ui_type: "numeric", ui_fldtype: "M" },
  { db_type: "number", ui_type: "numeric", ui_fldtype: "M" },
  { db_type: "FLOAT", ui_type: "numeric", ui_fldtype: "M" },
  { db_type: "NUMERIC", ui_type: "numeric", ui_fldtype: "M" },
]; */

export const engineTypes = [
  { engine: "airbyte", desc: "General" },
  { engine: "xbyte", desc: "Premium" },
  { engine: "xsap", desc: "SAP" },
];

export const healthSysList = [
  { sys: "app_db", desc: "Application" },
  { sys: "airbyte", desc: "Extraction" },
  { sys: "xbyte", desc: "Premium" },
  { sys: "xsap", desc: "SAP" },
  { sys: "airflow", desc: "Scheduler" },
  { sys: "neo4j", desc: "Metadata" },
];
