import pxToRem from "../functions/pxToRem";

export default function CardContent(theme) {
  return {
    MuiCardContent: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
          padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`,
          "&:last-child": {
            paddingBottom: 20,
          },
        },
      },
    },
  };
}
