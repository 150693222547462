import pxToRem from "../functions/pxToRem";

export default function MenuItem(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minWidth: pxToRem(160),
          minHeight: "unset",
          padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
          borderRadius: theme.borders.borderRadius.md,
          //fontSize: size.sm,
          color: theme.palette.text.main,
          transition: "background-color 300ms ease, color 300ms ease",

          "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus":
            {
              backgroundColor:
                theme.palette.mode === "light"
                  ? theme.palette.light.main
                  : theme.palette.dark.main,
              color:
                theme.palette.mode === "light"
                  ? theme.palette.dark.main
                  : theme.palette.white.main,
            },
        },
      },
    },
  };
}
