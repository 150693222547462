import pxToRem from "../functions/pxToRem";

export default function TableCell(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: `${pxToRem(12)} ${pxToRem(16)}`,
          borderBottom: `${theme.borders.borderWidth[1]} solid ${theme.palette.light.main}`,
        },
      },
    },
  };
}
