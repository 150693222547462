import { atom } from "recoil";

//current logged in user details
export const currentUserState = atom({
  key: "currentuser",
  default: {
    first_name: "",
    last_name: "",
    user_id: "",
    is_super: false,
    user_type: "",
  },
});

//list of subscriptions that current user has access to
export const userSubsListState = atom({
  key: "subslist",
  default: [],
});

// current selected subscription and user access to that subscription
export const currentSubsState = atom({
  key: "currentsubs",
  default: { subsid: "", subsname: "", role: "" },
});

//holds the currentModule selected by User
export const menuDraweropenState = atom({
  key: "menudraweropen",
  default: false,
});

export const currentModuleState = atom({
  key: "currentmodule",
  default: "",
});

//holds the subMenu selected by User
export const currentSubMenuState = atom({
  key: "currentsubmenu",
  default: "",
});

//holds the system Health Data
export const sysHealthState = atom({
  key: "syshealth",
  default: {},
});

// holds the source config values for Data Sync
export const sourceConfigState = atom({
  key: "sourceconfig",
  default: {},
});

//hold the allowed engines for the subscription
export const actEnginesState = atom({
  key: "actengines",
  default: [],
});

// holds the destination datalake config values for Data Sync
export const dlConfigState = atom({
  key: "dlconfig",
  default: {},
});

// holds the destination datawarehouse config values for Data Sync
export const dwConfigState = atom({
  key: "dwconfig",
  default: {},
});

//holds connection config details
export const connectionCatalogState = atom({
  key: "connectioncatalog",
  default: { catalog: {}, settings: {} },
});

//holds connection mode details
export const connectionModeState = atom({
  key: "connectionmode",
  default: "",
});

//holds data model nodes
export const modelNodesState = atom({
  key: "modelnodes",
  default: [],
});
//holds data model edges
export const modelEdgesState = atom({
  key: "modeledges",
  default: [],
});

//holds model mode details
export const modelModeState = atom({
  key: "modelmode",
  default: "",
});

//holds model mode counter
export const modelNodeCounterState = atom({
  key: "modelnodecounter",
  default: 0,
});

//holds model node Property Show/Close counter
export const showNodePropState = atom({
  key: "shownodeproperty",
  default: false,
});

//holds model selected node Properties
export const selNodePropState = atom({
  key: "selnodeprop",
  default: {},
});

//holds model node graph instance
export const modelRfInstanceState = atom({
  key: "modelrfinstance",
  default: null,
});

//holds data pipe nodes
export const pipeNodesState = atom({
  key: "pipenodes",
  default: [],
});
//holds data pipe edges
export const pipeEdgesState = atom({
  key: "pipeedges",
  default: [],
});

//holds pipeline mode details
export const pipeModeState = atom({
  key: "pipemode",
  default: "",
});

//holds model node graph instance
export const pipeRfInstanceState = atom({
  key: "piperfinstance",
  default: null,
});

//holds lineage nodes
export const lineageNodesState = atom({
  key: "lineagenodes",
  default: [],
});

//holds lineage edges
export const lineageEdgesState = atom({
  key: "lineageedges",
  default: [],
});

/////////////Pipeline Monitor//////////
//holds selected Run
export const pipeSelRunState = atom({
  key: "pipeselrun",
  default: { dag_run_id: "" },
});

//holds selected Task
export const pipeSelTaskState = atom({
  key: "pipeseltask",
  default: { task_id: "" },
});

//holds if the task level details to be shown
export const pipeShowTaskState = atom({
  key: "pipeshowtask",
  default: false,
});

///////////////////Governance///////////
////holds the data assets
export const dataAssetsState = atom({
  key: "dataassets",
  default: [],
});

//holds the selected Data Assets
export const selDataAssetState = atom({
  key: "seldataasset",
  default: {},
});

//holds the selected Data Quality Rule
export const selDataQualityRuleState = atom({
  key: "seldataqualityrule",
  default: {},
});

//////////////////USERS & ROLES/////////////
export const selRoleState = atom({
  key: "selrole",
  default: {},
});

export const selUserState = atom({
  key: "seluser",
  default: {},
});
