import pxToRem from "../functions/pxToRem";

export default function Badge(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: `${theme.palette.background.card} !important`,
          boxShadow: theme.boxShadows.md,
          padding: pxToRem(8),
          borderRadius: theme.borders.borderRadius.md,
        },
      },
    },
  };
}
