export const COLOR1 = "#66D9E1";
export const CGREY = "#AFAEC0";
export const LGREY = "#505057";
export const LGREEN = "#4ACB90";
export const LRED = "#FA3E3D";
export const LYELLOW = "#FDF8E1";
export const DYELLOW = "#F1C300";
export const DGREEN = "#00AF7B";
export const ADDCOLOR = "#DCF6F8";
export const REMCOLOR = "#FFE3E8";
export const UPDATECOLOR = "#FDF8E1";
export const COLOR2 = "#63DEC3";

export const SRNCOLOR = "#094F81";
export const LKNCOLOR = "#29A9E1";
export const JONCOLOR = "#007680";
export const UNNCOLOR = "#00B8C6";
export const AGNCOLOR = "#005E73";
export const STNCOLOR = "#922A8D";
export const SPNCOLOR = "#2F4858";
export const OTNCOLOR = "#00A26B";
export const SENCOLOR = "#00AF7B";

export const STRTCOLOR = "#00AF7B";
export const CONNCOLOR = "#29A9E1";
export const MODLCOLOR = "#922A8D";

export const MONQUEUE = "#808080";
export const MONSUCCESS = "#3BB44A";
export const MONFAILED = "#F75E68";
export const MONRUNNING = "#00D7CF";
export const MONUPSTREAMFAILED = "#FFA500";
export const MONNULL = "#d8b7b4";
export const MONUPFORRETRY = "#FFD601";
export const MONUPFORRESCHEDULE = "#40E0D0";
export const MONSKIPPED = "#FF68B4";
export const MONSCHEDULED = "#D2B48C";
export const MONRESTARTING = "#EE81ED";
export const MONREMOVED = "#D3D3D3";
export const MONDEFFERED = "#936FDA";

export const XGENC_P1_1 = "#922A8D";
export const XGENC_P1_2 = "#3BB44A";
export const XGENC_P1_3 = "#8BC541";
export const XGENC_P1_4 = "#D8DF27";
export const XGENC_P1_5 = "#29A9E1";
export const XGENC_P1_6 = "#0D72B9";

export const XGENC_P2_1 = "#CF377D";
export const XGENC_P2_2 = "#00A26B";
export const XGENC_P2_3 = "#3BB264";
export const XGENC_P2_4 = "#74CC57";
export const XGENC_P2_5 = "#00C2E3";
export const XGENC_P2_6 = "#0097CE";

export const XGENC_P3_1 = "#F75E68";
export const XGENC_P3_2 = "#008C7E";
export const XGENC_P3_3 = "#00997C";
export const XGENC_P3_4 = "#00AF7B";
export const XGENC_P3_5 = "#00D7CF";
export const XGENC_P3_6 = "#00B8C6";
