import pxToRem from "../functions/pxToRem";
export default function CardHeader(theme) {
  return {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderRadius: `${theme.borders.borderRadius.xl} ${theme.borders.borderRadius.xl} 0 0`,

          backgroundColor: theme.palette.background.cardheader,
          //color: theme.palette.primary.main,
          color: theme.palette.getContrastText(
            theme.palette.background.cardheader
          ),

          padding: `${theme.functions.pxToRem(8)} ${theme.functions.pxToRem(
            8
          )} ${theme.functions.pxToRem(8)}  ${theme.functions.pxToRem(8)}`,
        },
      },
    },
  };
}
