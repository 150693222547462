import axios from "axios";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL + "/api/v1",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": 86400,
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers["X-CSRF-TOKEN"] = getCookie("csrf_access_token");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/authenticate" && err.response) {
      // Access Token was expired
      if (err.response?.status === 401 && !originalConfig._retry) {
        window.location.href = "/login";
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
