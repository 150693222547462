import pxToRem from "../functions/pxToRem";

export default function DialogContent(theme) {
  return {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: pxToRem(16),
          fontSize: theme.typography.body2,
          color: theme.palette.text.main,
        },

        dividers: {
          borderTop: `${theme.borders.borderWidth[1]} solid ${theme.borders.borderColor}`,
          borderBottom: `${theme.borders.borderWidth[1]} solid ${theme.borders.borderColor}`,
        },
      },
    },
  };
}
