//React

//Mui
import {
  Box,
  List,
  ListItem,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";

//Icons
import StatusIcon from "@mui/icons-material/Circle";

//3rd Party
import { useRecoilValue } from "recoil";

//Css

//Local
import { healthSysList } from "../../../../constants/appconstant";

//Components

//Recoil State
import { sysHealthState } from "../../../../store/atoms/AppState";

//API Service

//Const

export default function HealthCheck({ anchorHealthEl, onClose }) {
  const theme = useTheme();
  const openHealthStatus = Boolean(anchorHealthEl);

  const sysHealth = useRecoilValue(sysHealthState);

  return (
    <Popover
      id="mouse-over-info"
      open={openHealthStatus}
      anchorEl={anchorHealthEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <List>
        {healthSysList.map((sys) => (
          <ListItem key={sys.sys}>
            <Box
              display="inline-flex"
              sx={{
                width: "100%",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <StatusIcon
                fontSize="small"
                sx={{
                  color:
                    sysHealth[sys.sys]?.status === undefined
                      ? theme.palette.grey[600]
                      : sysHealth[sys.sys]?.status
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                }}
              />
              <Typography variant="body2"> {sys.desc}</Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}
