import pxToRem from "../functions/pxToRem";

export default function TableHead(theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          //display: "block",
          padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
          borderRadius: `${theme.borders.borderRadius.xl} ${theme.borders.borderRadius.xl} 0 0`,
          backgroundColor: theme.palette.background.cardheader,
        },
      },
    },
  };
}
