export default function DialogContentText(theme) {
  return {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.body2,
          color: theme.palette.text.main,
        },
      },
    },
  };
}
