import api from "./api.service";
import Session from "./session.service";

const authenticate = async (useremail, password) => {
  return api.post(
    "/auth/authenticate",
    {},
    {
      auth: {
        username: useremail,
        password: password,
      },
    }
  );
};

const usersubslogin = async (subsid) => {
  return api.post("/auth/subscription-access", {
    subscription_id: subsid,
  });
};

const userChangeInitialPassword = async (user) => {
  return api.patch("/users/profile", user);
};

const logout = async () => {
  return api.post("/auth/logout").then((resp) => {
    Session.closeSession();
    return resp;
  });
};

const isAuthenticated = () => {
  if (Session.getUser()) {
    return true;
  } else return false;
};

const isAuthorised = () => {
  if (Session.getCurrentSubs()) {
    return true;
  } else return false;
};

const hasModuleAccess = (mod) => {
  const subsaccess = Session.getCurrentSubs();

  let hasAccess = false;

  mod.forEach((element) => {
    if (
      subsaccess.access.role.find((doc) => doc.modulename === element) !==
      undefined
    ) {
      hasAccess = true;
    }
  });

  return hasAccess;
};

const hasModuleActivityAccess = (mod, act) => {
  const subsaccess = Session.getCurrentSubs();

  const modaccess = subsaccess.access.role.find(
    (doc) => doc.modulename === mod
  );
  if (modaccess === undefined) {
    return false;
  } else {
    return modaccess.moduleaccess[act];
  }
};

const Auth = {
  authenticate,
  usersubslogin,
  userChangeInitialPassword,
  logout,
  isAuthenticated,
  isAuthorised,
  hasModuleAccess,
  hasModuleActivityAccess,
};

export default Auth;
