import { Box, Container, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import Loginbgb from "../../assets/img/loginbgb.png";
import Loginbgw from "../../assets/img/loginbgw.png";
import { Outlet } from "react-router-dom";

export default function LoginLayout() {
  const theme = useTheme();
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        justifyContent: "flex-start",
        height: "100vh",
        width: "100vw",
        backgroundSize: "cover",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          md={6}
          lg={6}
          sx={{
            height: "100vh",
            margin: 0,
            textAlign: "center",
            backgroundColor:
              theme.palette.mode === "dark" ? "#28292F" : "#F6F7FB",
          }}
        >
          <Box
            component="img"
            alt="xGen"
            src={theme.palette.mode === "dark" ? Loginbgb : Loginbgw}
            sx={{
              width: "100%",
              height: "80vh",
              objectFit: "contain",
            }}
          />
          <Typography variant="h4" sx={{ mt: 3, fontWeight: 700 }}>
            Unified Data Management
          </Typography>
        </Grid>
        <Grid md={6} lg={6}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
}
