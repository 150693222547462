//React

//Mui
import { Container } from "@mui/material";

//Icons

//3rd Party
import { Outlet } from "react-router-dom";

//Css

//Local
import { useViewport } from "../../context/viewport-context";

//Components

//Recoil State

//API Service

//Const

export default function PageLayout() {
  const { height } = useViewport();
  return (
    <Container
      disableGutters={true}
      maxWidth={false}
      sx={{
        height: height,
        pl: 0,
        pr: 0,
        justifyContent: "flex-start",
        //overflow: "auto",
      }}
    >
      <Outlet />
    </Container>
  );
}
