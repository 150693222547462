//React

//MUI

//ICONS

//3rd-Party

//Local
import { RiseLoader } from "react-spinners";

//Components

//Recoil State

//API Service

//Const

export default function Loading() {
  return (
    <div
      style={{
        zIndex: 1,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RiseLoader color="#36d7b7" />
    </div>
  );
}
