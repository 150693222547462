import React from "react";
import { Box } from "@mui/material";
import imgOops from "../../assets/img/oops.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box>
          <img src={imgOops} alt="Something went wrong."></img>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
