//React
import React, { Suspense, useEffect, useMemo, useState } from "react";

//Mui
import {
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";

//Icons

//3rd Party
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

//Css
import "./css/App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-js-cron/dist/styles.css";

//Local
import router from "./routes/router";
import { ColorModeContext } from "./context/color-context";
import { ViewportProvider } from "./context/viewport-context";
import { getThemeOptions } from "./themes/theme";

//Components
import ErrorBoundary from "./pages/Common/ErrorBoundary";
import Loading from "./components/Common/Loading";

//Recoil State

//API Service

//Const

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  let theme = useMemo(() => getThemeOptions(mode), [mode]);
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    setMode(prefersDarkMode ? "dark" : "light");
  }, [prefersDarkMode]);

  return (
    <div className="body">
      <ErrorBoundary>
        <ViewportProvider>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ToastContainer
                position="bottom-center"
                theme={theme.palette.mode === "dark" ? "dark" : "light"}
                style={{ width: "40%" }}
              />
              <Suspense fallback={<Loading />}>
                <RecoilRoot>
                  <RouterProvider router={router} />
                </RecoilRoot>
              </Suspense>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </ViewportProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
