//React

//Mui
import { Box, ListItem, ListItemButton, Menu, Typography } from "@mui/material";

//Icons
import SupportIcon from "@mui/icons-material/SupportAgentOutlined";
import CampaignIcon from "@mui/icons-material/CampaignOutlined";
import DocumentIcon from "@mui/icons-material/DifferenceOutlined";
import VersionIcon from "@mui/icons-material/ModelTraining";

//3rd Party

//Css

//Local
import { XGEN_APP_VERSION } from "../../../../constants/appconstant";

//Components

//Recoil State

//API Service

//Const

export default function HelpSupport({ anchorHelpEl, onClose }) {
  const openHelpList = Boolean(anchorHelpEl);
  return (
    <Menu
      id="subs-list"
      anchorEl={anchorHelpEl}
      open={openHelpList}
      onClose={onClose}
      MenuListProps={{
        dense: true,
      }}
    >
      <ListItemButton dense={true}>
        <ListItem>
          <Box
            display="inline-flex"
            sx={{
              width: "100%",
              alignItems: "flex-start",
              textAlign: "flex-start",
              //justifyContent: "space-evenly",
              columnGap: 3,
            }}
          >
            <SupportIcon fontSize="medium" />

            <Typography variant="caption">{"Contact Support"}</Typography>
          </Box>
        </ListItem>
      </ListItemButton>

      <ListItemButton dense={true}>
        <ListItem>
          <Box
            display="inline-flex"
            sx={{
              width: "100%",
              alignItems: "flex-start",
              textAlign: "flex-start",
              //justifyContent: "space-evenly",
              columnGap: 3,
            }}
          >
            <CampaignIcon />

            <Typography variant="caption">{"Feedback"}</Typography>
          </Box>
        </ListItem>
      </ListItemButton>

      <ListItemButton dense={true}>
        <ListItem>
          <Box
            display="inline-flex"
            sx={{
              width: "100%",
              alignItems: "flex-start",
              textAlign: "flex-start",
              //justifyContent: "space-evenly",
              columnGap: 3,
            }}
          >
            <DocumentIcon />

            <Typography variant="caption">{"Documentation"}</Typography>
          </Box>
        </ListItem>
      </ListItemButton>

      <ListItemButton dense={true}>
        <ListItem>
          <Box
            display="inline-flex"
            sx={{
              width: "100%",
              alignItems: "flex-start",
              textAlign: "flex-start",
              //justifyContent: "space-evenly",
              columnGap: 3,
            }}
          >
            <VersionIcon />

            <Typography variant="caption">{`Version: ${XGEN_APP_VERSION}`}</Typography>
          </Box>
        </ListItem>
      </ListItemButton>
    </Menu>
  );
}
