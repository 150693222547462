export default function DialogTitle(theme) {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          height: 40,
          fontSize: 14,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          padding: "10px 24px",
        },
      },
    },
  };
}
