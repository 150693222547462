import { alpha } from "@mui/material";
import colorsLight from "./theme-light/colors";
import colorsDark from "./theme-dark/colors";

const CustomShadows = (mode) => ({
  button: `0 2px #0000000b`,
  text: `0 -1px 0 rgb(0 0 0 / 12%)`,
  z1: `0px 2px 8px ${alpha(
    mode === "light" ? colorsLight.grey[900] : colorsDark.grey[300],
    0.15
  )}`,
  // only available in paid version
});

export default CustomShadows;
