//React
import PropTypes from "prop-types";

//Mui
import { Box, IconButton, Typography, useTheme } from "@mui/material";

//Icons
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

//3rd Party
import { useRecoilState } from "recoil";

//Css
import xGenLogo from "../../../../assets/logo/xgenlogo.png";

//Local

//Components
import DrawerHeaderStyled from "./DrawerHeaderStyled";

//Recoil State
import { menuDraweropenState } from "../../../../store/atoms/AppState";

//API Service

//Const

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  const [menuDraweropen, setMenuDrawerOpen] =
    useRecoilState(menuDraweropenState);

  const handleMenuDrawerToggle = () => {
    setMenuDrawerOpen(!menuDraweropen);
  };

  const handleMenuDrawerClose = () => {
    setMenuDrawerOpen(false);
  };

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <IconButton
        aria-label="open drawer"
        onClick={handleMenuDrawerToggle}
        //edge="start"
      >
        {/* <MenuIcon /> */}
        <img src={xGenLogo} alt="xGEN" style={{ height: 30 }} />
      </IconButton>

      {menuDraweropen && (
        <>
          <Box
            display="inline-flex"
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                justifyContent: "center",
                textAlign: "center",
                ml: 2,
                mr: 2,
              }}
            >
              xGEN
            </Typography>
            <IconButton
              size="small"
              disableRipple
              aria-label="open drawer"
              onClick={handleMenuDrawerClose}
              edge="start"
              sx={{
                ml: { xs: 0, lg: -2 },
              }}
            >
              <MenuOpenIcon />
            </IconButton>
          </Box>
        </>
      )}
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
